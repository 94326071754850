interface ChatAgent {
  id: string;
  name: string;
  role: string;
  avatar: string;
}

export const chatAgents: ChatAgent[] = [
  {
    id: '1',
    name: 'Sarah Martin',
    role: 'Conseillère VTC',
    avatar: '/agents/sarah.jpg'
  },
  {
    id: '2',
    name: 'Thomas Dubois',
    role: 'Expert Transport',
    avatar: '/agents/thomas.jpg'
  },
  {
    id: '3',
    name: 'Marie Laurent',
    role: 'Assistante Clientèle',
    avatar: '/agents/marie.jpg'
  }
];

export const getRandomAgent = (): ChatAgent => {
  const randomIndex = Math.floor(Math.random() * chatAgents.length);
  return chatAgents[randomIndex];
};
