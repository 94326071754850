import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, addDoc, updateDoc, deleteDoc, doc, where } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../lib/firebase';
import { toast } from 'react-hot-toast';
import { User, UserPlus, Trash2, Edit2, Save } from 'lucide-react';

interface Admin {
  id: string;
  email: string;
  name: string;
  role: 'admin' | 'superadmin';
  createdAt: any;
}

const AdminProfile = () => {
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [newAdmin, setNewAdmin] = useState({ email: '', password: '', name: '', role: 'admin' });
  const [editingAdmin, setEditingAdmin] = useState<string | null>(null);
  const [editForm, setEditForm] = useState({ name: '', role: 'admin' });
  const [currentUserRole, setCurrentUserRole] = useState<string>('');

  useEffect(() => {
    fetchAdmins();
    checkCurrentUserRole();
  }, []);

  const checkCurrentUserRole = async () => {
    const user = auth.currentUser;
    if (user) {
      const adminDoc = await getDocs(query(collection(db, 'admins'), where('email', '==', user.email)));
      if (!adminDoc.empty) {
        setCurrentUserRole(adminDoc.docs[0].data().role);
      }
    }
  };

  const fetchAdmins = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'admins'));
      const adminList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Admin[];
      setAdmins(adminList);
    } catch (error) {
      console.error('Error fetching admins:', error);
      toast.error('Erreur lors du chargement des administrateurs');
    }
  };

  const handleCreateAdmin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Créer l'utilisateur dans Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        newAdmin.email,
        newAdmin.password
      );

      // Ajouter les informations admin dans Firestore
      await addDoc(collection(db, 'admins'), {
        email: newAdmin.email,
        name: newAdmin.name,
        role: newAdmin.role,
        createdAt: new Date(),
        uid: userCredential.user.uid
      });

      toast.success('Administrateur créé avec succès');
      setNewAdmin({ email: '', password: '', name: '', role: 'admin' });
      fetchAdmins();
    } catch (error: any) {
      console.error('Error creating admin:', error);
      toast.error(error.message || 'Erreur lors de la création de l\'administrateur');
    }
  };

  const handleUpdateAdmin = async (adminId: string) => {
    try {
      await updateDoc(doc(db, 'admins', adminId), {
        name: editForm.name,
        role: editForm.role,
        updatedAt: new Date()
      });

      toast.success('Administrateur mis à jour avec succès');
      setEditingAdmin(null);
      fetchAdmins();
    } catch (error) {
      console.error('Error updating admin:', error);
      toast.error('Erreur lors de la mise à jour de l\'administrateur');
    }
  };

  const handleDeleteAdmin = async (adminId: string, adminEmail: string) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer cet administrateur ?')) {
      return;
    }

    try {
      await deleteDoc(doc(db, 'admins', adminId));
      toast.success('Administrateur supprimé avec succès');
      fetchAdmins();
    } catch (error) {
      console.error('Error deleting admin:', error);
      toast.error('Erreur lors de la suppression de l\'administrateur');
    }
  };

  const startEditing = (admin: Admin) => {
    setEditingAdmin(admin.id);
    setEditForm({ name: admin.name, role: admin.role });
  };

  if (currentUserRole !== 'superadmin') {
    return (
      <div className="p-6">
        <div className="bg-yellow-50 p-4 rounded-lg">
          <p className="text-yellow-800">
            Accès restreint. Seuls les super administrateurs peuvent gérer les comptes administrateurs.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white shadow-md rounded-lg">
        <div className="p-6 border-b">
          <h2 className="text-2xl font-bold text-gray-800">Gestion des Administrateurs</h2>
        </div>

        <div className="p-6">
          {/* Formulaire de création */}
          <form onSubmit={handleCreateAdmin} className="mb-8 bg-gray-50 p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-4 flex items-center">
              <UserPlus className="w-5 h-5 mr-2" />
              Ajouter un nouvel administrateur
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <input
                type="email"
                placeholder="Email"
                value={newAdmin.email}
                onChange={(e) => setNewAdmin({ ...newAdmin, email: e.target.value })}
                className="px-4 py-2 border rounded-lg"
                required
              />
              <input
                type="password"
                placeholder="Mot de passe"
                value={newAdmin.password}
                onChange={(e) => setNewAdmin({ ...newAdmin, password: e.target.value })}
                className="px-4 py-2 border rounded-lg"
                required
              />
              <input
                type="text"
                placeholder="Nom"
                value={newAdmin.name}
                onChange={(e) => setNewAdmin({ ...newAdmin, name: e.target.value })}
                className="px-4 py-2 border rounded-lg"
                required
              />
              <select
                value={newAdmin.role}
                onChange={(e) => setNewAdmin({ ...newAdmin, role: e.target.value as 'admin' | 'superadmin' })}
                className="px-4 py-2 border rounded-lg"
              >
                <option value="admin">Administrateur</option>
                <option value="superadmin">Super Administrateur</option>
              </select>
            </div>
            <button
              type="submit"
              className="mt-4 bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600"
            >
              Créer
            </button>
          </form>

          {/* Liste des administrateurs */}
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Nom</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Email</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Rôle</th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {admins.map((admin) => (
                  <tr key={admin.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4">
                      {editingAdmin === admin.id ? (
                        <input
                          type="text"
                          value={editForm.name}
                          onChange={(e) => setEditForm({ ...editForm, name: e.target.value })}
                          className="px-2 py-1 border rounded"
                        />
                      ) : (
                        <div className="flex items-center">
                          <User className="w-5 h-5 mr-2 text-gray-400" />
                          <span>{admin.name}</span>
                        </div>
                      )}
                    </td>
                    <td className="px-6 py-4 text-gray-500">{admin.email}</td>
                    <td className="px-6 py-4">
                      {editingAdmin === admin.id ? (
                        <select
                          value={editForm.role}
                          onChange={(e) => setEditForm({ ...editForm, role: e.target.value as 'admin' | 'superadmin' })}
                          className="px-2 py-1 border rounded"
                        >
                          <option value="admin">Administrateur</option>
                          <option value="superadmin">Super Administrateur</option>
                        </select>
                      ) : (
                        <span className={`px-2 py-1 rounded-full text-xs ${
                          admin.role === 'superadmin' ? 'bg-purple-100 text-purple-800' : 'bg-blue-100 text-blue-800'
                        }`}>
                          {admin.role === 'superadmin' ? 'Super Admin' : 'Admin'}
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 text-right">
                      {editingAdmin === admin.id ? (
                        <button
                          onClick={() => handleUpdateAdmin(admin.id)}
                          className="text-green-600 hover:text-green-900 mr-2"
                        >
                          <Save className="w-5 h-5" />
                        </button>
                      ) : (
                        <button
                          onClick={() => startEditing(admin)}
                          className="text-blue-600 hover:text-blue-900 mr-2"
                        >
                          <Edit2 className="w-5 h-5" />
                        </button>
                      )}
                      <button
                        onClick={() => handleDeleteAdmin(admin.id, admin.email)}
                        className="text-red-600 hover:text-red-900"
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProfile;
