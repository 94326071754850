import { motion } from 'framer-motion';
import { ArrowRight, Calendar, Clock, MapPin } from 'lucide-react';
import { useState, useEffect, useRef } from 'react';
import ReservationModal from './ReservationModal';

interface AddressSuggestion {
  place_id: string;
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
}

export default function HeroSlider() {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    pickup: '',
    dropoff: '',
    date: '',
    time: '',
    pickupPlaceId: '',
    dropoffPlaceId: ''
  });

  const [pickupSuggestions, setPickupSuggestions] = useState<AddressSuggestion[]>([]);
  const [dropoffSuggestions, setDropoffSuggestions] = useState<AddressSuggestion[]>([]);
  const [showPickupSuggestions, setShowPickupSuggestions] = useState(false);
  const [showDropoffSuggestions, setShowDropoffSuggestions] = useState(false);

  const autocompleteService = useRef<google.maps.places.AutocompleteService | null>(null);
  const placesService = useRef<google.maps.places.PlacesService | null>(null);
  const sessionToken = useRef<google.maps.places.AutocompleteSessionToken | null>(null);

  useEffect(() => {
    if (window.google && !autocompleteService.current) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      const tempDiv = document.createElement('div');
      placesService.current = new window.google.maps.places.PlacesService(tempDiv);
      sessionToken.current = new window.google.maps.places.AutocompleteSessionToken();
    }
  }, []);

  const getPlacePredictions = async (input: string, type: 'pickup' | 'dropoff') => {
    if (!input || !autocompleteService.current) return;

    try {
      const request = {
        input,
        componentRestrictions: { country: 'fr' },
        sessionToken: sessionToken.current,
        types: ['establishment', 'geocode'],
        language: 'fr'
      };

      const predictions = await new Promise<google.maps.places.AutocompletePrediction[]>((resolve, reject) => {
        autocompleteService.current?.getPlacePredictions(
          request,
          (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && results) {
              resolve(results);
            } else {
              reject(status);
            }
          }
        );
      });

      const sortedPredictions = predictions.sort((a, b) => {
        const isAirportA = a.description.toLowerCase().includes('aéroport') || 
                          a.description.toLowerCase().includes('airport');
        const isAirportB = b.description.toLowerCase().includes('aéroport') || 
                          b.description.toLowerCase().includes('airport');
        const isStationA = a.description.toLowerCase().includes('gare');
        const isStationB = b.description.toLowerCase().includes('gare');
        
        if (isAirportA && !isAirportB) return -1;
        if (!isAirportA && isAirportB) return 1;
        if (isStationA && !isStationB) return -1;
        if (!isStationA && isStationB) return 1;
        return 0;
      });

      if (type === 'pickup') {
        setPickupSuggestions(sortedPredictions);
        setShowPickupSuggestions(true);
      } else {
        setDropoffSuggestions(sortedPredictions);
        setShowDropoffSuggestions(true);
      }
    } catch (error) {
      console.error('Error fetching predictions:', error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'pickup' | 'dropoff') => {
    const { value } = e.target;
    setFormData(prev => ({ ...prev, [type]: value }));
    getPlacePredictions(value, type);
  };

  const handleSuggestionClick = (suggestion: AddressSuggestion, type: 'pickup' | 'dropoff') => {
    setFormData(prev => ({
      ...prev,
      [type]: suggestion.structured_formatting.main_text + ' - ' + suggestion.structured_formatting.secondary_text,
      [`${type}PlaceId`]: suggestion.place_id
    }));
    if (type === 'pickup') {
      setShowPickupSuggestions(false);
    } else {
      setShowDropoffSuggestions(false);
    }
  };

  const handleQuickBook = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.pickup && formData.dropoff && formData.date && formData.time) {
      setShowModal(true);
    }
  };

  const today = new Date().toISOString().split('T')[0];

  return (
    <div className="relative h-screen">
      {/* Image de fond */}
      <div className="absolute inset-0">
        <img
          src="https://www.drive2spot.com/wp-content/uploads/2024/09/Decouvrez_le_luxe_ultime_voitures_avec_chauffeur_pour_un_voyage_inoubliable.webp"
          alt="Elite Transfer VTC"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/60" />
      </div>

      {/* Contenu */}
      <div className="relative h-full flex items-center">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center text-white"
          >
            <h1 className="text-4xl md:text-6xl font-bold mb-6">
              Service de Transport VTC Premium
            </h1>
            <p className="text-xl md:text-2xl mb-12 max-w-3xl mx-auto">
              Voyagez avec style et confort. Notre service de chauffeur privé vous garantit une expérience de transport exceptionnelle.
            </p>

            {/* Formulaire de réservation rapide */}
            <motion.form
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              onSubmit={handleQuickBook}
              className="max-w-4xl mx-auto bg-white/10 backdrop-blur-md p-6 rounded-xl shadow-2xl border border-white/20"
            >
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <div className="relative">
                  <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 text-yellow-500 w-5 h-5" />
                  <input
                    type="text"
                    placeholder="Lieu de départ"
                    value={formData.pickup}
                    onChange={(e) => handleInputChange(e, 'pickup')}
                    className="w-full pl-10 pr-4 py-3 bg-white/10 border border-white/20 rounded-lg focus:ring-2 focus:ring-yellow-500 focus:border-transparent placeholder-white/70 text-white"
                    required
                  />
                  {showPickupSuggestions && pickupSuggestions.length > 0 && (
                    <div className="absolute z-10 w-full mt-1 bg-[#1E1E1E] border border-gray-700 rounded-lg shadow-xl max-h-60 overflow-y-auto">
                      {pickupSuggestions.map((suggestion) => (
                        <button
                          key={suggestion.place_id}
                          type="button"
                          onClick={() => handleSuggestionClick(suggestion, 'pickup')}
                          className="w-full px-4 py-2 text-left text-gray-300 hover:bg-gray-800 first:rounded-t-lg last:rounded-b-lg"
                        >
                          <div className="font-medium">{suggestion.structured_formatting.main_text}</div>
                          <div className="text-sm text-gray-400">{suggestion.structured_formatting.secondary_text}</div>
                        </button>
                      ))}
                    </div>
                  )}
                </div>
                <div className="relative">
                  <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 text-yellow-500 w-5 h-5" />
                  <input
                    type="text"
                    placeholder="Lieu d'arrivée"
                    value={formData.dropoff}
                    onChange={(e) => handleInputChange(e, 'dropoff')}
                    className="w-full pl-10 pr-4 py-3 bg-white/10 border border-white/20 rounded-lg focus:ring-2 focus:ring-yellow-500 focus:border-transparent placeholder-white/70 text-white"
                    required
                  />
                  {showDropoffSuggestions && dropoffSuggestions.length > 0 && (
                    <div className="absolute z-10 w-full mt-1 bg-[#1E1E1E] border border-gray-700 rounded-lg shadow-xl max-h-60 overflow-y-auto">
                      {dropoffSuggestions.map((suggestion) => (
                        <button
                          key={suggestion.place_id}
                          type="button"
                          onClick={() => handleSuggestionClick(suggestion, 'dropoff')}
                          className="w-full px-4 py-2 text-left text-gray-300 hover:bg-gray-800 first:rounded-t-lg last:rounded-b-lg"
                        >
                          <div className="font-medium">{suggestion.structured_formatting.main_text}</div>
                          <div className="text-sm text-gray-400">{suggestion.structured_formatting.secondary_text}</div>
                        </button>
                      ))}
                    </div>
                  )}
                </div>
                <div className="relative">
                  <Calendar className="absolute left-3 top-1/2 -translate-y-1/2 text-yellow-500 w-5 h-5" />
                  <input
                    type="date"
                    value={formData.date}
                    min={today}
                    onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                    className="w-full pl-10 pr-4 py-3 bg-white/10 border border-white/20 rounded-lg focus:ring-2 focus:ring-yellow-500 focus:border-transparent text-white"
                    required
                  />
                </div>
                <div className="relative">
                  <Clock className="absolute left-3 top-1/2 -translate-y-1/2 text-yellow-500 w-5 h-5" />
                  <input
                    type="time"
                    value={formData.time}
                    onChange={(e) => setFormData({ ...formData, time: e.target.value })}
                    className="w-full pl-10 pr-4 py-3 bg-white/10 border border-white/20 rounded-lg focus:ring-2 focus:ring-yellow-500 focus:border-transparent text-white"
                    required
                  />
                </div>
              </div>
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="mt-6 w-full md:w-auto px-8 py-3 bg-gradient-to-r from-yellow-500 to-yellow-600 text-black rounded-lg font-medium hover:from-yellow-600 hover:to-yellow-700 transition-all flex items-center justify-center mx-auto group"
              >
                Réserver maintenant
                <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
              </motion.button>
            </motion.form>
          </motion.div>
        </div>
      </div>

      {/* Modal de réservation */}
      {showModal && (
        <ReservationModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          formData={formData}
        />
      )}
    </div>
  );
}
