import BookingForm from '../components/BookingForm';

export default function BookingPage() {
  return (
    <div className="min-h-screen bg-neutral-50 pt-16">
      <div className="w-full max-w-[95%] mx-auto py-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-3xl md:text-4xl font-bold text-neutral-900 mb-6 text-center">
            Réservation
          </h1>
          <p className="text-lg text-neutral-600 mb-8 text-center max-w-3xl mx-auto">
            Réservez votre transfert en quelques clics. Notre service premium vous garantit un voyage confortable et ponctuel.
          </p>
          <div className="w-full">
            <BookingForm />
          </div>
        </div>
      </div>
    </div>
  );
}