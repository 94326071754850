import React from 'react';
import { motion } from 'framer-motion';
import { Facebook, Instagram, Mail, Phone, MapPin } from 'lucide-react';
import ParticleBackground from './ParticleBackground';
import { Link } from 'react-router-dom';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="relative bg-[#121212] text-gray-300 w-full">
      {/* Particules en arrière-plan */}
      <div className="absolute inset-0 h-full overflow-hidden">
        <ParticleBackground />
      </div>

      {/* Grille futuriste en arrière-plan */}
      <div className="absolute inset-0 pointer-events-none bg-[linear-gradient(to_right,#232323_1px,transparent_1px),linear-gradient(to_bottom,#232323_1px,transparent_1px)] bg-[size:24px_24px] opacity-30"></div>

      {/* Effet de lueur */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: [0.4, 1, 0.4] }}
        transition={{ duration: 3, repeat: Infinity }}
        className="absolute top-0 left-1/2 -translate-x-1/2 w-[70%] h-1 bg-gradient-to-r from-transparent via-yellow-500 to-transparent blur-sm pointer-events-none"
      />

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 py-12">
          {/* À propos */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="space-y-4"
          >
            <h3 className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-600">
              À propos d'Elite Transfert
            </h3>
            <p className="text-gray-400">
              Votre partenaire de confiance pour tous vos déplacements VIP. Service premium, confort et élégance pour une expérience de transport exceptionnelle.
            </p>
            <div className="flex space-x-4">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-yellow-500 transition-colors">
                <Facebook className="w-5 h-5" />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-yellow-500 transition-colors">
                <Instagram className="w-5 h-5" />
              </a>
            </div>
          </motion.div>

          {/* Services */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
            className="space-y-4"
          >
            <h3 className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-600">
              Nos Services
            </h3>
            <ul className="space-y-2">
              <li>
                <Link to="/reservation" className="text-gray-400 hover:text-yellow-500 transition-colors">
                  Transport VTC Premium
                </Link>
              </li>
              <li>
                <Link to="/reservation" className="text-gray-400 hover:text-yellow-500 transition-colors">
                  Transferts Aéroport
                </Link>
              </li>
              <li>
                <Link to="/reservation" className="text-gray-400 hover:text-yellow-500 transition-colors">
                  Service Corporate
                </Link>
              </li>
              <li>
                <Link to="/reservation" className="text-gray-400 hover:text-yellow-500 transition-colors">
                  Événements Spéciaux
                </Link>
              </li>
            </ul>
          </motion.div>

          {/* Liens utiles */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="space-y-4"
          >
            <h3 className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-600">
              Liens Utiles
            </h3>
            <ul className="space-y-2">
              <li>
                <Link to="/#about" className="text-gray-400 hover:text-yellow-500 transition-colors">
                  À propos
                </Link>
              </li>
              <li>
                <Link to="/#services" className="text-gray-400 hover:text-yellow-500 transition-colors">
                  Services
                </Link>
              </li>
              <li>
                <Link to="/#testimonials" className="text-gray-400 hover:text-yellow-500 transition-colors">
                  Témoignages
                </Link>
              </li>
              <li>
                <Link to="/#contact" className="text-gray-400 hover:text-yellow-500 transition-colors">
                  Contact
                </Link>
              </li>
            </ul>
          </motion.div>

          {/* Contact */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.3 }}
            className="space-y-4"
          >
            <h3 className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-600">
              Contact
            </h3>
            <ul className="space-y-2">
              <li className="flex items-center space-x-2">
                <Phone className="w-5 h-5 text-yellow-500" />
                <a href="tel:+33123456789" className="text-gray-400 hover:text-yellow-500 transition-colors">
                  +33 1 23 45 67 89
                </a>
              </li>
              <li className="flex items-center space-x-2">
                <Mail className="w-5 h-5 text-yellow-500" />
                <a href="mailto:contact@elitetransfert.com" className="text-gray-400 hover:text-yellow-500 transition-colors">
                  contact@elitetransfert.com
                </a>
              </li>
              <li className="flex items-center space-x-2">
                <MapPin className="w-5 h-5 text-yellow-500" />
                <span className="text-gray-400">
                  Paris, France
                </span>
              </li>
            </ul>
          </motion.div>
        </div>

        {/* Copyright */}
        <div className="border-t border-yellow-500/10">
          <p className="text-center text-sm text-gray-400 py-4">
            {currentYear} Elite Transfert. Tous droits réservés.
          </p>
        </div>
      </div>
    </footer>
  );
}