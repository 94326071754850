import React from 'react';
import { startOfMonth, endOfMonth, eachDayOfInterval, format, isToday, isSameDay } from 'date-fns';
import { fr } from 'date-fns/locale';

interface Booking {
  id: string;
  scheduledDate: string;
  scheduledTime: string;
  firstName: string;
  lastName: string;
  status: string;
}

interface BookingCalendarProps {
  bookings: Booking[];
  onDayClick: (date: Date) => void;
}

const BookingCalendar: React.FC<BookingCalendarProps> = ({ bookings, onDayClick }) => {
  const currentDate = new Date();
  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(currentDate);
  const daysInMonth = eachDayOfInterval({ start: monthStart, end: monthEnd });

  // Grouper les réservations par date
  const bookingsByDate = bookings.reduce((acc, booking) => {
    const date = booking.scheduledDate;
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(booking);
    return acc;
  }, {} as Record<string, Booking[]>);

  return (
    <div className="bg-white dark:bg-dark-card rounded-lg shadow overflow-hidden">
      {/* En-tête du calendrier */}
      <div className="p-4 border-b dark:border-dark-border">
        <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
          {format(currentDate, 'MMMM yyyy', { locale: fr })}
        </h2>
      </div>

      {/* Grille du calendrier */}
      <div className="grid grid-cols-7 gap-px bg-gray-200 dark:bg-dark-border">
        {/* En-têtes des jours */}
        {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((day) => (
          <div
            key={day}
            className="p-2 text-center font-medium bg-white dark:bg-dark-card text-gray-900 dark:text-white"
          >
            {day}
          </div>
        ))}

        {/* Jours du mois */}
        {daysInMonth.map((date) => {
          const dateStr = format(date, 'yyyy-MM-dd');
          const dayBookings = bookingsByDate[dateStr] || [];
          const isCurrentDay = isToday(date);

          return (
            <div
              key={dateStr}
              onClick={() => onDayClick(date)}
              className={`min-h-[100px] p-2 bg-white dark:bg-dark-card border-t dark:border-dark-border cursor-pointer
                ${isCurrentDay ? 'bg-blue-50 dark:bg-blue-900/20' : ''}
                hover:bg-gray-50 dark:hover:bg-dark-hover transition-colors`}
            >
              <div className="flex justify-between items-start">
                <span
                  className={`text-sm font-medium ${
                    isCurrentDay
                      ? 'text-blue-600 dark:text-blue-400'
                      : 'text-gray-900 dark:text-white'
                  }`}
                >
                  {format(date, 'd')}
                </span>
                {dayBookings.length > 0 && (
                  <span className="px-1.5 py-0.5 text-xs rounded-full bg-blue-100 dark:bg-blue-900 text-blue-600 dark:text-blue-400">
                    {dayBookings.length}
                  </span>
                )}
              </div>

              {/* Aperçu des réservations */}
              <div className="mt-1 space-y-1">
                {dayBookings.slice(0, 2).map((booking) => (
                  <div
                    key={booking.id}
                    className="text-xs truncate text-gray-600 dark:text-gray-300"
                  >
                    {booking.scheduledTime} - {booking.firstName} {booking.lastName}
                  </div>
                ))}
                {dayBookings.length > 2 && (
                  <div className="text-xs text-gray-500 dark:text-gray-400">
                    +{dayBookings.length - 2} autres
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BookingCalendar;
