import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  collection, 
  query, 
  onSnapshot,
  orderBy,
  limit,
  where,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  addDoc,
  serverTimestamp
} from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { auth, db } from '../lib/firebase';
import { toast } from 'react-hot-toast';
import { format, addHours, parse, startOfWeek, getDay, subDays } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useAuthStore } from '../store/authStore';
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { 
  User, 
  UserPlus,
  FileText,
  Settings,
  HelpCircle,
  Search,
  Star,
  Filter,
  X,
  Bell,
  Calendar,
  ChevronDown,
  Plus,
  LogOut,
  Clock,
  CheckCircle,
  XCircle,
  Wrench,
  MapPin,
  ArrowRight,
  DollarSign,
  TrendingUp,
  Download,
  Car,
  Mail,
  Phone,
  Trash2,
  MoreVertical,
  Eye,
  Edit2,
  AlertCircle
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Tab } from '@headlessui/react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { 
  MapContainer, 
  TileLayer, 
  Marker, 
  Popup 
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Styles pour le PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: 30
  },
  header: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  booking: {
    marginBottom: 15,
    padding: 10,
    borderBottom: 1,
    borderBottomColor: '#eee'
  },
  label: {
    fontSize: 12,
    color: '#666'
  },
  value: {
    fontSize: 14,
    marginBottom: 5
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
    textAlign: 'center'
  },
  text: {
    fontSize: 14,
    marginBottom: 5
  }
});

// Composant PDF pour les réservations
const BookingPDF = ({ bookings }: { bookings: any[] }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.header}>Elite Transfert - Liste des Réservations</Text>
      <View style={styles.section}>
        {bookings.map((booking, index) => (
          <View key={index} style={styles.booking}>
            <Text style={styles.label}>Client</Text>
            <Text style={styles.value}>{`${booking.firstName} ${booking.lastName}`}</Text>
            
            <Text style={styles.label}>Date et Heure</Text>
            <Text style={styles.value}>{`${booking.scheduledDate} ${booking.scheduledTime}`}</Text>
            
            <Text style={styles.label}>Statut</Text>
            <Text style={styles.value}>{booking.status}</Text>
            
            {booking.price && (
              <>
                <Text style={styles.label}>Prix</Text>
                <Text style={styles.value}>{formatPrice(booking.price)}</Text>
              </>
            )}
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

// Composant PDF pour une réservation
const ReservationPDF = ({ booking }: { booking: any }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>Détails de la Réservation</Text>
        <Text style={styles.text}>ID: {booking?.id || 'N/A'}</Text>
        <Text style={styles.text}>Client: {booking?.firstName} {booking?.lastName}</Text>
        <Text style={styles.text}>Email: {booking?.email || 'N/A'}</Text>
        <Text style={styles.text}>Téléphone: {booking?.phone || 'N/A'}</Text>
        <Text style={styles.text}>Départ: {booking?.pickup?.address || 'N/A'}</Text>
        <Text style={styles.text}>Arrivée: {booking?.dropoff?.address || 'N/A'}</Text>
        <Text style={styles.text}>Date: {booking?.scheduledDate || 'N/A'}</Text>
        <Text style={styles.text}>Heure: {booking?.scheduledTime || 'N/A'}</Text>
        <Text style={styles.text}>Statut: {booking?.status || 'N/A'}</Text>
        <Text style={styles.text}>Prix: {booking?.price || 0}€</Text>
        <Text style={styles.text}>Véhicule: {booking?.vehicle?.name || 'N/A'}</Text>
        <Text style={styles.text}>Passagers: {booking?.passengers || 0}</Text>
        <Text style={styles.text}>Bagages: {booking?.luggage || 0}</Text>
        {booking?.notes && <Text style={styles.text}>Notes: {booking.notes}</Text>}
      </View>
    </Page>
  </Document>
);

// Enregistrement des composants Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

// Fonction utilitaire pour formater les prix
const formatPrice = (price: number): string => {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR'
  }).format(price);
};

// Fonctions utilitaires pour les statuts
const getStatusColor = (status: string): string => {
  switch (status) {
    case 'pending':
      return 'bg-yellow-100 text-yellow-800';
    case 'confirmed':
      return 'bg-green-100 text-green-800';
    case 'cancelled':
      return 'bg-red-100 text-red-800';
    case 'completed':
      return 'bg-blue-100 text-blue-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
};

const getStatusText = (status: string): string => {
  switch (status) {
    case 'pending':
      return 'En attente';
    case 'confirmed':
      return 'Confirmée';
    case 'cancelled':
      return 'Annulée';
    case 'completed':
      return 'Terminée';
    default:
      return 'Inconnu';
  }
};

// Fonction utilitaire pour convertir les timestamps Firestore en Date de manière sécurisée
const convertFirestoreTimestamp = (timestamp: any): Date | null => {
  if (!timestamp) {
    return null;
  }
  
  try {
    // Si c'est déjà un objet Date
    if (timestamp instanceof Date) {
      return timestamp;
    }

    // Si c'est un timestamp Firestore
    if (timestamp && typeof timestamp.toDate === 'function') {
      return timestamp.toDate();
    }

    // Si c'est un timestamp avec des secondes et nanosecondes
    if (timestamp && typeof timestamp === 'object' && 
        'seconds' in timestamp && 'nanoseconds' in timestamp) {
      return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    }

    // Si c'est un timestamp avec seulement des secondes
    if (timestamp && typeof timestamp === 'object' && 'seconds' in timestamp) {
      return new Date(timestamp.seconds * 1000);
    }

    // Essayer de créer une date à partir de la valeur
    const date = new Date(timestamp);
    if (!isNaN(date.getTime())) {
      return date;
    }

    return null;
  } catch (error) {
    console.error('Erreur lors de la conversion du timestamp:', error, 'Valeur:', timestamp);
    return null;
  }
};

// Fonction utilitaire pour formater les dates de manière sécurisée
const formatSafeDate = (timestamp: any): string => {
  if (!timestamp) return 'Date non spécifiée';
  
  const date = convertFirestoreTimestamp(timestamp);
  if (!date) return 'Date invalide';
  
  try {
    return format(date, 'dd/MM/yyyy HH:mm', { locale: fr });
  } catch (error) {
    console.error('Erreur lors du formatage de la date:', error);
    return 'Date invalide';
  }
};

// Types
interface Booking {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  pickup: {
    address: string;
    coordinates?: {
      lat: number;
      lng: number;
    };
  };
  dropoff: {
    address: string;
    coordinates?: {
      lat: number;
      lng: number;
    };
  };
  scheduledDate: string;
  scheduledTime: string;
  status: string;
  price: number;
  vehicle: {
    name: string;
    type: string;
  };
  passengers: number;
  luggage: number;
  createdAt: Date | null;
  formattedCreatedAt: string;
  rawCreatedAt?: any; // For debugging purposes
}

interface Admin {
  id: string;
  email: string;
  name: string;
  role: string;
}

interface DashboardStats {
  totalBookings: number;
  pendingBookings: number;
  confirmedBookings: number;
  cancelledBookings: number;
  completedBookings: number;
  totalRevenue: number;
  averagePrice: number;
  totalPassengers: number;
  averageDistance: number;
  paymentMethodStats: Record<string, number>;
  vehicleTypeStats: Record<string, number>;
}

// Styles constants
const actionButtonColors = {
  blue: 'border-blue-500 text-blue-500',
  green: 'border-green-500 text-green-500',
  purple: 'border-purple-500 text-purple-500',
  orange: 'border-orange-500 text-orange-500'
} as const;

// Composant pour les actions rapides
const QuickActions: React.FC = () => {
  const actions = [
    { icon: UserPlus, label: 'Nouvelle réservation', color: 'blue' as const },
    { icon: FileText, label: 'Générer rapport', color: 'green' as const },
    { icon: Settings, label: 'Paramètres', color: 'purple' as const },
    { icon: HelpCircle, label: 'Support', color: 'orange' as const }
  ];

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
      {actions.map(({ icon: Icon, label, color }) => (
        <button
          key={label}
          className={`p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow
            flex flex-col items-center justify-center gap-2 border-t-4 ${actionButtonColors[color]}`}
          onClick={() => {
            console.log(`Clicked: ${label}`);
          }}
        >
          <Icon className="w-6 h-6" />
          <span className="text-sm font-medium text-gray-700">{label}</span>
        </button>
      ))}
    </div>
  );
};

// Composant pour la recherche globale
const GlobalSearch: React.FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({ value, onChange }) => (
  <div className="relative">
    <input
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Rechercher une réservation..."
      className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
    />
    <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
  </div>
);

// Composant pour les notifications
const NotificationBadge: React.FC<{ count: number }> = ({ count }) => (
  <motion.div
    initial={{ scale: 0 }}
    animate={{ scale: 1 }}
    className="absolute -top-1 -right-1 w-5 h-5 bg-red-500 text-white text-xs rounded-full flex items-center justify-center"
  >
    {count}
  </motion.div>
);

// Composant pour les statistiques détaillées
const DetailedStats: React.FC<{ stats: any }> = ({ stats }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
    {/* Performance des chauffeurs */}
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h3 className="text-lg font-semibold mb-4">Performance Chauffeurs</h3>
      <div className="space-y-4">
        {stats.drivers.map((driver: any) => (
          <div key={driver.id} className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                <User className="w-5 h-5 text-gray-600" />
              </div>
              <div>
                <p className="font-medium">{driver.name}</p>
                <div className="flex items-center text-sm text-gray-500">
                  <Star className="w-4 h-4 text-yellow-400 fill-current" />
                  <span className="ml-1">{driver.rating}</span>
                </div>
              </div>
            </div>
            <div className="text-right">
              <p className="text-sm font-medium">{driver.completedRides} courses</p>
              <p className="text-sm text-green-500">{driver.onTimePercentage}% à l'heure</p>
            </div>
          </div>
        ))}
      </div>
    </div>

    {/* Statistiques des véhicules */}
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h3 className="text-lg font-semibold mb-4">État de la Flotte</h3>
      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-gray-50 p-4 rounded-lg">
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm text-gray-500">En service</span>
              <span className="text-lg font-semibold text-green-600">{stats.fleet.active}</span>
            </div>
            <div className="flex items-center gap-2">
              <Car className="w-4 h-4 text-gray-400" />
              <span className="text-sm">Véhicules actifs</span>
            </div>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg">
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm text-gray-500">Maintenance</span>
              <span className="text-lg font-semibold text-yellow-600">{stats.fleet.maintenance}</span>
            </div>
            <div className="flex items-center gap-2">
              <Wrench className="w-4 h-4 text-gray-400" />
              <span className="text-sm">En révision</span>
            </div>
          </div>
        </div>
        {stats.fleet.types.map((type: any) => (
          <div key={type.name} className="bg-gray-50 p-4 rounded-lg">
            <div className="flex items-center justify-between mb-2">
              <span className="font-medium">{type.name}</span>
              <span className="text-sm text-gray-600">{type.available}/{type.total} disponibles</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className="bg-blue-600 rounded-full h-2"
                style={{ width: `${(type.available / type.total) * 100}%` }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>

    {/* Analyse des trajets */}
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h3 className="text-lg font-semibold mb-4">Analyse des Trajets</h3>
      <div className="space-y-4">
        {stats.routes.map((route: any) => (
          <div key={route.id} className="bg-gray-50 p-4 rounded-lg">
            <div className="flex items-center justify-between mb-2">
              <div className="space-y-1">
                <div className="flex items-center gap-2">
                  <MapPin className="w-4 h-4 text-gray-400" />
                  <span className="text-sm font-medium">{route.from}</span>
                </div>
                <div className="flex items-center gap-2">
                  <ArrowRight className="w-4 h-4 text-gray-400" />
                  <span className="text-sm font-medium">{route.to}</span>
                </div>
              </div>
              <div className="text-right">
                <p className="text-lg font-semibold">{route.count}</p>
                <p className="text-sm text-green-500">+{route.growth}%</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

// Composant pour les actions contextuelles
const ContextualActions: React.FC<{ booking: any }> = ({ booking }) => {
  const [showActions, setShowActions] = useState(false);

  return (
    <div className="relative">
      <button
        onClick={() => setShowActions(!showActions)}
        className="p-2 hover:bg-gray-100 rounded-full transition-colors"
      >
        <MoreVertical className="w-5 h-5 text-gray-500" />
      </button>
      
      <AnimatePresence>
        {showActions && (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-10"
          >
            <button
              onClick={() => {/* Logique pour voir les détails */}}
              className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 flex items-center gap-2"
            >
              <Eye className="w-4 h-4" />
              <span>Voir les détails</span>
            </button>
            <button
              onClick={() => {/* Logique pour modifier */}}
              className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 flex items-center gap-2"
            >
              <Edit2 className="w-4 h-4" />
              <span>Modifier</span>
            </button>
            <button
              onClick={() => {/* Logique pour annuler */}}
              className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 flex items-center gap-2 text-red-600"
            >
              <X className="w-4 h-4" />
              <span>Annuler</span>
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

// Composant pour la carte des trajets actifs
const LiveMap: React.FC<{ bookings: any[] }> = ({ bookings }) => (
  <div className="bg-white p-6 rounded-lg shadow-sm mb-6">
    <h3 className="text-lg font-semibold mb-4">Carte des trajets en cours</h3>
    <div className="h-[400px] rounded-lg overflow-hidden">
      <MapContainer
        center={[48.8566, 2.3522]} // Paris
        zoom={11}
        className="h-full w-full"
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; OpenStreetMap contributors'
        />
        {bookings
          .filter(b => b.status === 'confirmed')
          .map(booking => (
            <Marker
              key={booking.id}
              position={[booking.pickup?.lat || 48.8566, booking.pickup?.lng || 2.3522]}
            >
              <Popup>
                <div className="p-2">
                  <p className="font-medium">{booking.firstName} {booking.lastName}</p>
                  <p className="text-sm text-gray-600">{booking.scheduledTime}</p>
                  <p className="text-sm text-gray-600">{booking.pickup?.address}</p>
                </div>
              </Popup>
            </Marker>
          ))}
      </MapContainer>
    </div>
  </div>
);

// Composant pour les métriques circulaires
const CircularMetric: React.FC<{
  value: number;
  title: string;
  color: string;
}> = ({ value, title, color }) => (
  <div className="flex flex-col items-center">
    <div className="w-24 h-24">
      <CircularProgressbar
        value={value}
        text={`${value}%`}
        styles={buildStyles({
          pathColor: color,
          textColor: color,
          trailColor: '#f3f4f6'
        })}
      />
    </div>
    <p className="mt-2 text-sm font-medium text-gray-600">{title}</p>
  </div>
);

// Composant pour le tableau de bord des KPIs
const KPIDashboard: React.FC<{ stats: any }> = ({ stats }) => (
  <div className="bg-white p-6 rounded-lg shadow-sm mb-6">
    <h3 className="text-lg font-semibold mb-6">Indicateurs de Performance</h3>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      <CircularMetric
        value={stats.satisfaction}
        title="Satisfaction client"
        color="#10B981"
      />
      <CircularMetric
        value={stats.onTime}
        title="Ponctualité"
        color="#3B82F6"
      />
      <CircularMetric
        value={stats.completion}
        title="Taux de complétion"
        color="#8B5CF6"
      />
    </div>
  </div>
);

// Composant pour les alertes et notifications
const AlertCenter: React.FC<{ alerts: any[] }> = ({ alerts }) => (
  <div className="fixed bottom-4 right-4 z-50">
    <AnimatePresence>
      {alerts.map(alert => (
        <motion.div
          key={alert.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          className={`mb-2 p-4 rounded-lg shadow-lg ${
            alert.type === 'success' ? 'bg-green-500' :
            alert.type === 'warning' ? 'bg-yellow-500' :
            'bg-red-500'
          } text-white`}
        >
          <div className="flex items-center gap-2">
            {alert.type === 'success' ? (
              <CheckCircle className="w-5 h-5" />
            ) : alert.type === 'warning' ? (
              <AlertCircle className="w-5 h-5" />
            ) : (
              <XCircle className="w-5 h-5" />
            )}
            <p>{alert.message}</p>
          </div>
        </motion.div>
      ))}
    </AnimatePresence>
  </div>
);

// Composant pour les statistiques financières
const FinancialStats: React.FC<{ data: any }> = ({ data }) => (
  <div className="bg-white p-6 rounded-lg shadow-sm mb-6">
    <h3 className="text-lg font-semibold mb-4">Statistiques Financières</h3>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div className="p-4 bg-gray-50 rounded-lg">
        <p className="text-sm text-gray-500 mb-1">Revenu mensuel</p>
        <div className="flex items-end gap-2">
          <p className="text-2xl font-bold">{formatPrice(data.monthlyRevenue)}</p>
          <p className={`text-sm ${
            data.revenueGrowth >= 0 ? 'text-green-500' : 'text-red-500'
          }`}>
            {data.revenueGrowth >= 0 ? '+' : ''}{data.revenueGrowth}%
          </p>
        </div>
      </div>
      <div className="p-4 bg-gray-50 rounded-lg">
        <p className="text-sm text-gray-500 mb-1">Ticket moyen</p>
        <p className="text-2xl font-bold">{formatPrice(data.averageTicket)}</p>
      </div>
      <div className="p-4 bg-gray-50 rounded-lg">
        <p className="text-sm text-gray-500 mb-1">Prévisions</p>
        <div className="flex items-end gap-2">
          <p className="text-2xl font-bold">{formatPrice(data.forecast)}</p>
          <p className="text-sm text-blue-500">Prochain mois</p>
        </div>
      </div>
    </div>
  </div>
);

// Composant pour le calendrier des réservations
const BookingCalendar: React.FC<{ bookings: Booking[] }> = ({ bookings }) => {
  const locales = { fr };
  const localizer = useMemo(() => dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  }), []);

  const events = useMemo(() => {
    return bookings?.map(booking => ({
      id: booking.id,
      title: `${booking.firstName || ''} ${booking.lastName || ''}`,
      start: new Date(booking.scheduledDate + 'T' + (booking.scheduledTime || '00:00:00')),
      end: new Date(booking.scheduledDate + 'T' + (booking.scheduledTime || '00:00:00')),
      resource: booking
    })) || [];
  }, [bookings]);

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm mb-6">
      <h3 className="text-lg font-semibold mb-4">Calendrier des réservations</h3>
      <div className="h-[600px]">
        <BigCalendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          culture="fr"
          views={['month', 'week', 'day']}
          defaultView="month"
          messages={{
            today: "Aujourd'hui",
            previous: 'Précédent',
            next: 'Suivant',
            month: 'Mois',
            week: 'Semaine',
            day: 'Jour',
            noEventsInRange: 'Aucune réservation sur cette période'
          }}
          eventPropGetter={() => ({
            style: {
              backgroundColor: '#3B82F6',
              color: 'white',
              border: 'none',
              borderRadius: '4px'
            }
          })}
          onSelectEvent={(event) => {
            console.log('Réservation sélectionnée:', event);
          }}
        />
      </div>
    </div>
  );
};

// Composant pour les statistiques avancées
const AdvancedAnalytics: React.FC<{ data: any }> = ({ data }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-sm mb-6">
      <h3 className="text-lg font-semibold mb-6">Analyses avancées</h3>
      <Tab.Group>
        <Tab.List className="flex space-x-4 border-b">
          {['Performance', 'Revenus', 'Satisfaction'].map((tabName) => (
            <Tab
              key={tabName}
              className={({ selected }) =>
                `px-4 py-2 text-sm font-medium focus:outline-none ${
                  selected
                    ? 'text-blue-600 border-b-2 border-blue-600'
                    : 'text-gray-500 hover:text-gray-700'
                }`
              }
            >
              {tabName}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels>
          <Tab.Panel>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Performance des chauffeurs */}
              <div className="space-y-4">
                <h4 className="font-medium">Taux de conversion</h4>
                <div className="relative pt-1">
                  <div className="flex mb-2 items-center justify-between">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                        {data.performance.conversionRate}%
                      </span>
                    </div>
                  </div>
                  <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                    <div
                      className="h-full bg-blue-500"
                      style={{ width: `${data.performance.conversionRate}%` }}
                    />
                  </div>
                </div>
              </div>
              <div className="space-y-4">
                <h4 className="font-medium">Temps moyen de réponse</h4>
                <div className="text-2xl font-bold text-gray-700">
                  {data.performance.averageResponseTime} min
                </div>
              </div>
            </div>
          </Tab.Panel>

          <Tab.Panel>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h4 className="font-medium mb-4">Répartition des revenus</h4>
                <div className="space-y-2">
                  {Object.entries(data.revenue.distribution).map(([key, value]) => (
                    <div key={key} className="flex items-center justify-between">
                      <span className="text-sm text-gray-600">{key}</span>
                      <span className="font-medium">{formatPrice(value)}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <h4 className="font-medium mb-4">Prévisions</h4>
                <div className="space-y-4">
                  {data.revenue.forecast.map((item) => (
                    <div key={item.period} className="flex items-center justify-between">
                      <span className="text-sm text-gray-600">{item.period}</span>
                      <div className="flex items-center gap-2">
                        <span className="font-medium">{formatPrice(item.amount)}</span>
                        <span className={`text-sm ${
                          item.growth >= 0 ? 'text-green-500' : 'text-red-500'
                        }`}>
                          {item.growth >= 0 ? '+' : ''}{item.growth}%
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Tab.Panel>

          <Tab.Panel>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h4 className="font-medium mb-4">Notes des clients</h4>
                <div className="space-y-4">
                  {[5, 4, 3, 2, 1].map((rating) => (
                    <div key={rating} className="flex items-center gap-4">
                      <div className="flex items-center">
                        {Array.from({ length: rating }).map((_, i) => (
                          <Star key={i} className="w-4 h-4 text-yellow-400 fill-current" />
                        ))}
                      </div>
                      <div className="flex-1">
                        <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                          <div
                            className="bg-yellow-400 rounded-full h-2"
                            style={{ width: `${(data.satisfaction.ratings[rating] / 100) * 100}%` }}
                          />
                        </div>
                      </div>
                      <span className="text-sm text-gray-600">
                        {data.satisfaction.ratings[rating]}%
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <h4 className="font-medium mb-4">Commentaires récents</h4>
                <div className="space-y-4">
                  {data.satisfaction.recentComments.map((comment) => (
                    <div key={comment.id} className="p-4 bg-gray-50 rounded-lg">
                      <div className="flex items-center gap-2 mb-2">
                        <div className="flex">
                          {Array.from({ length: comment.rating }).map((_, i) => (
                            <Star key={i} className="w-4 h-4 text-yellow-400 fill-current" />
                          ))}
                        </div>
                        <span className="text-sm text-gray-500">
                          {format(new Date(comment.date), 'dd/MM/yyyy')}
                        </span>
                      </div>
                      <p className="text-sm text-gray-700">{comment.text}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

// Types pour les analyses avancées
interface AnalyticsData {
  performance: {
    conversionRate: number;
    averageResponseTime: number;
  };
  revenue: {
    distribution: Record<string, number>;
    forecast: Array<{
      period: string;
      amount: number;
      growth: number;
    }>;
  };
  satisfaction: {
    ratings: Record<number, number>;
    recentComments: Array<{
      id: number;
      rating: number;
      date: string;
      text: string;
    }>;
  };
}

// Composant pour le calendrier flottant
const FloatingCalendar: React.FC<{
  selectedDate: Date;
  onSelect: (date: Date) => void;
  onClose: () => void;
}> = ({ selectedDate, onSelect, onClose }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className="absolute right-0 top-full mt-2 bg-white rounded-lg shadow-lg p-4 z-50"
    >
      <div className="relative">
        <button
          onClick={onClose}
          className="absolute right-2 top-2 text-gray-500 hover:text-gray-700"
        >
          <X className="w-4 h-4" />
        </button>
        <DayPicker
          mode="single"
          selected={selectedDate}
          onSelect={(date) => date && onSelect(date)}
          locale={fr}
          className="p-2"
          modifiersClassNames={{
            selected: 'bg-blue-500 text-white rounded-full',
            today: 'text-blue-500 font-bold',
          }}
        />
      </div>
    </motion.div>
  );
};

interface QuickStatsProps {
  stats: {
    totalBookings: number;
    pendingBookings: number;
    completedBookings: number;
    cancelledBookings: number;
    totalRevenue: number;
    completionRate: number;
  };
}

const QuickStats: React.FC<QuickStatsProps> = ({ stats }) => {
  const statItems = [
    {
      label: 'Réservations totales',
      value: stats.totalBookings,
      color: 'text-blue-600',
      bgColor: 'bg-blue-100',
      icon: Calendar
    },
    {
      label: 'En attente',
      value: stats.pendingBookings,
      color: 'text-yellow-600',
      bgColor: 'bg-yellow-100',
      icon: Clock
    },
    {
      label: 'Complétées',
      value: stats.completedBookings,
      color: 'text-green-600',
      bgColor: 'bg-green-100',
      icon: CheckCircle
    },
    {
      label: 'Annulées',
      value: stats.cancelledBookings,
      color: 'text-red-600',
      bgColor: 'bg-red-100',
      icon: XCircle
    },
    {
      label: 'Revenu total',
      value: `${stats.totalRevenue.toLocaleString('fr-FR')} €`,
      color: 'text-purple-600',
      bgColor: 'bg-purple-100',
      icon: DollarSign
    },
    {
      label: 'Taux de complétion',
      value: `${stats.completionRate}%`,
      color: 'text-indigo-600',
      bgColor: 'bg-indigo-100',
      icon: TrendingUp
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {statItems.map((item, index) => (
        <div
          key={index}
          className="bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow"
        >
          <div className="flex items-center justify-between mb-4">
            <div className={`${item.bgColor} p-3 rounded-full`}>
              <item.icon className={`w-6 h-6 ${item.color}`} />
            </div>
            <span className={`text-2xl font-bold ${item.color}`}>
              {item.value}
            </span>
          </div>
          <h3 className="text-gray-600 font-medium">{item.label}</h3>
        </div>
      ))}
    </div>
  );
};

const Eliteadmin92: React.FC = () => {
  const navigate = useNavigate();
  const { user, loading } = useAuthStore();
  
  useEffect(() => {
    if (!loading && !user) {
      navigate('/login');
    }
  }, [user, loading, navigate]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (!user) {
    return null;
  }

  const [bookings, setBookings] = useState<Booking[]>([]);
  const [filteredBookings, setFilteredBookings] = useState<Booking[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [showAdminPanel, setShowAdminPanel] = useState(false);
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [newAdmin, setNewAdmin] = useState({ email: '', password: '', name: '', role: 'admin' });
  const [editingAdmin, setEditingAdmin] = useState<string | null>(null);
  const [editForm, setEditForm] = useState({ name: '', role: 'admin' });
  const [currentUserRole, setCurrentUserRole] = useState<string>('');
  const [stats, setStats] = useState<DashboardStats>({
    totalBookings: 0,
    pendingBookings: 0,
    confirmedBookings: 0,
    cancelledBookings: 0,
    completedBookings: 0,
    totalRevenue: 0,
    averagePrice: 0,
    totalPassengers: 0,
    averageDistance: 0,
    paymentMethodStats: {},
    vehicleTypeStats: {}
  });
  const chartRef = useRef<ChartJS | null>(null);

  const [filters, setFilters] = useState({
    status: [] as string[],
    dateRange: {
      start: null as Date | null,
      end: null as Date | null
    },
    priceRange: {
      min: 0,
      max: 1000
    },
    sortBy: 'date'
  });

  const [selectedPeriod, setSelectedPeriod] = useState('week');
  const [showFilters, setShowFilters] = useState(false);
  const [notifications, setNotifications] = useState<Array<{ id: string; message: string; read: boolean }>>([]);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [alerts, setAlerts] = useState<Array<{
    id: string;
    type: 'success' | 'warning' | 'error';
    message: string;
  }>>([]);

  // Données mockées pour toutes les statistiques
  const mockStats = {
    drivers: [
      { id: 1, name: "Thomas D.", rating: 4.9, completedRides: 156, onTimePercentage: 98 },
      { id: 2, name: "Sophie M.", rating: 4.8, completedRides: 142, onTimePercentage: 97 },
      { id: 3, name: "Jean L.", rating: 4.7, completedRides: 128, onTimePercentage: 96 }
    ],
    fleet: {
      active: 12,
      maintenance: 3,
      types: [
        { name: "Berline", available: 5, total: 6 },
        { name: "SUV", available: 4, total: 5 },
        { name: "Van", available: 3, total: 4 }
      ]
    },
    routes: [
      { id: 1, from: "Aéroport CDG", to: "Paris Centre", count: 245, growth: 12 },
      { id: 2, from: "Paris Centre", to: "Versailles", count: 189, growth: 8 },
      { id: 3, from: "Orly", to: "Paris Centre", count: 167, growth: 5 }
    ],
    kpis: {
      satisfaction: 95,
      onTime: 92,
      completion: 98
    },
    financial: {
      monthlyRevenue: 45000,
      revenueGrowth: 12.5,
      averageTicket: 120,
      forecast: 52000
    },
    performance: {
      conversionRate: 68,
      averageResponseTime: 8,
    },
    revenue: {
      distribution: {
        'Courses standard': 25000,
        'Courses premium': 15000,
        'Événements spéciaux': 5000
      },
      forecast: [
        { period: 'Ce mois', amount: 45000, growth: 12.5 },
        { period: 'Prochain mois', amount: 52000, growth: 15.6 },
        { period: 'Dans 3 mois', amount: 60000, growth: 15.4 }
      ]
    },
    satisfaction: {
      ratings: {
        5: 45,
        4: 30,
        3: 15,
        2: 7,
        1: 3
      },
      recentComments: [
        {
          id: 1,
          rating: 5,
          date: '2024-01-15',
          text: 'Excellent service, chauffeur très professionnel.'
        },
        {
          id: 2,
          rating: 4,
          date: '2024-01-14',
          text: 'Très bonne expérience globale.'
        }
      ]
    }
  };

  // Function to apply filters
  const applyFilters = (bookings: Booking[]) => {
    return bookings.filter(booking => {
      // Date range filter
      if (filters.dateRange.start && filters.dateRange.end) {
        const bookingDate = new Date(booking.scheduledDate);
        if (bookingDate < filters.dateRange.start || bookingDate > filters.dateRange.end) {
          return false;
        }
      }

      // Price range filter
      if (booking.price < filters.priceRange.min || booking.price > filters.priceRange.max) {
        return false;
      }

      // Status filter
      if (filters.status.length > 0 && !filters.status.includes(booking.status)) {
        return false;
      }

      return true;
    });
  };

  // Écouter les réservations en temps réel
  useEffect(() => {
    if (!user) {
      console.log("Pas d'utilisateur connecté");
      return;
    }

    console.log("Initialisation de l'écoute des réservations...");
    const reservationsRef = collection(db, 'reservations');
    
    const unsubscribe = onSnapshot(reservationsRef, (snapshot) => {
      console.log("Réception de nouvelles données:", snapshot.size, "réservations");
      
      snapshot.docs.forEach(doc => {
        const data = doc.data();
        console.log("Réservation brute:", {
          id: doc.id,
          data: data,
          dataKeys: Object.keys(data),
          pickup: data.pickup,
          dropoff: data.dropoff,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phone,
          scheduledDate: data.scheduledDate,
          scheduledTime: data.scheduledTime,
          status: data.status,
          price: data.price,
          vehicle: data.vehicle,
          passengers: data.passengers,
          luggage: data.luggage
        });
      });

      const reservationsData = snapshot.docs.map(doc => {
        const data = doc.data();
        const createdAtDate = data.createdAt ? convertFirestoreTimestamp(data.createdAt) : null;
        
        return {
          id: doc.id,
          firstName: data.firstName || data.prenom || '',
          lastName: data.lastName || data.nom || '',
          email: data.email || '',
          phone: data.phone || data.telephone || '',
          pickup: {
            address: data.pickup?.address || data.depart || '',
            coordinates: data.pickup?.coordinates || null
          },
          dropoff: {
            address: data.dropoff?.address || data.arrivee || '',
            coordinates: data.dropoff?.coordinates || null
          },
          scheduledDate: data.scheduledDate || data.date || '',
          scheduledTime: data.scheduledTime || data.heure || '',
          status: data.status || 'pending',
          price: typeof data.price === 'number' ? data.price : (typeof data.prix === 'number' ? data.prix : 0),
          vehicle: {
            name: data.vehicle?.name || data.vehicule?.nom || '',
            type: data.vehicle?.type || data.vehicule?.type || ''
          },
          passengers: typeof data.passengers === 'number' ? data.passengers : (typeof data.passagers === 'number' ? data.passagers : 0),
          luggage: typeof data.luggage === 'number' ? data.luggage : (typeof data.bagages === 'number' ? data.bagages : 0),
          createdAt: createdAtDate,
          formattedCreatedAt: createdAtDate ? format(createdAtDate, 'dd/MM/yyyy HH:mm', { locale: fr }) : 'Date non spécifiée'
        };
        
        console.log("Réservation transformée:", reservationsData);
      }) as Booking[];
      
      const sortedReservations = [...reservationsData].sort((a, b) => 
        b.createdAt?.getTime() - a.createdAt?.getTime() || 0
      );

      setBookings(sortedReservations);
      setFilteredBookings(sortedReservations);
      
      // Mettre à jour les statistiques
      const newStats = {
        totalBookings: sortedReservations.length,
        pendingBookings: sortedReservations.filter(b => b.status === 'pending').length,
        confirmedBookings: sortedReservations.filter(b => b.status === 'confirmed').length,
        cancelledBookings: sortedReservations.filter(b => b.status === 'cancelled').length,
        completedBookings: sortedReservations.filter(b => b.status === 'completed').length,
        totalRevenue: sortedReservations.reduce((acc, b) => acc + (b.price || 0), 0),
        averagePrice: sortedReservations.length > 0 ? sortedReservations.reduce((acc, b) => acc + (b.price || 0), 0) / sortedReservations.length : 0,
        totalPassengers: sortedReservations.reduce((acc, b) => acc + (b.passengers || 0), 0),
        averageDistance: 0,
        paymentMethodStats: {},
        vehicleTypeStats: sortedReservations.reduce((acc, b) => {
          const type = b.vehicle?.type || 'unknown';
          acc[type] = (acc[type] || 0) + 1;
          return acc;
        }, {} as Record<string, number>)
      };
      
      setStats(newStats);
    }, (error) => {
      console.error("Erreur lors de l'écoute des réservations:", error);
      toast.error("Erreur lors du chargement des réservations");
    });

    return () => {
      console.log("Nettoyage de l'écoute des réservations");
      unsubscribe();
    };
  }, [user]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      toast.success('Déconnexion réussie');
      navigate('/login');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
      toast.error('Erreur lors de la déconnexion');
    }
  };

  const checkAndCreateAdmin = async (email: string) => {
    try {
      const adminsQuery = query(
        collection(db, 'admins'),
        where('email', '==', email)
      );
      const querySnapshot = await getDocs(adminsQuery);
      
      if (querySnapshot.empty) {
        console.log("Création de l'administrateur...");
        await addDoc(collection(db, 'admins'), {
          email: email,
          role: 'admin',
          createdAt: serverTimestamp()
        });
        console.log("Administrateur créé avec succès");
        return true;
      }
      
      return !querySnapshot.empty;
    } catch (error) {
      console.error("Erreur lors de la création de l'administrateur:", error);
      return false;
    }
  };

  const checkCurrentUserRole = async () => {
    const user = auth.currentUser;
    console.log("Utilisateur actuel:", user?.email);

    if (!user) {
      console.log("Aucun utilisateur connecté");
      navigate('/login');
      return false;
    }

    try {
      // Vérifier et créer l'admin si nécessaire
      const isAdmin = await checkAndCreateAdmin(user.email!);
      
      if (!isAdmin) {
        console.log("L'utilisateur n'est pas un administrateur");
        toast.error("Accès non autorisé");
        navigate('/login');
        return false;
      }

      console.log("Accès administrateur confirmé");
      return true;
    } catch (error) {
      console.error("Erreur lors de la vérification du rôle:", error);
      toast.error("Erreur lors de la vérification des droits d'accès");
      navigate('/login');
      return false;
    }
  };

  // Fonctions de gestion des administrateurs
  const fetchAdmins = async () => {
    try {
      const adminsSnapshot = await getDocs(collection(db, 'admins'));
      const adminsData = adminsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Admin[];
      setAdmins(adminsData);
    } catch (error) {
      console.error('Erreur lors du chargement des administrateurs:', error);
      toast.error('Erreur lors du chargement des administrateurs');
    }
  };

  const handleCreateAdmin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Créer un nouvel utilisateur avec Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, newAdmin.email, newAdmin.password);
      
      // Ajouter l'administrateur dans Firestore
      await addDoc(collection(db, 'admins'), {
        uid: userCredential.user.uid,
        email: newAdmin.email,
        name: newAdmin.name,
        role: newAdmin.role,
        createdAt: new Date()
      });

      setNewAdmin({ email: '', password: '', name: '', role: 'admin' });
      toast.success('Administrateur créé avec succès');
      fetchAdmins();
    } catch (error) {
      console.error('Erreur lors de la création de l\'administrateur:', error);
      toast.error('Erreur lors de la création de l\'administrateur');
    }
  };

  const handleUpdateAdmin = async (adminId: string) => {
    try {
      await updateDoc(doc(db, 'admins', adminId), {
        name: editForm.name,
        role: editForm.role
      });
      setEditingAdmin(null);
      toast.success('Administrateur mis à jour avec succès');
      fetchAdmins();
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'administrateur:', error);
      toast.error('Erreur lors de la mise à jour de l\'administrateur');
    }
  };

  const handleDeleteAdmin = async (adminId: string) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet administrateur ?')) {
      try {
        await deleteDoc(doc(db, 'admins', adminId));
        toast.success('Administrateur supprimé avec succès');
        fetchAdmins();
      } catch (error) {
        console.error('Erreur lors de la suppression de l\'administrateur:', error);
        toast.error('Erreur lors de la suppression de l\'administrateur');
      }
    }
  };

  const startEditing = (admin: Admin) => {
    setEditingAdmin(admin.id);
    setEditForm({ name: admin.name, role: admin.role });
  };

  const exportToCSV = () => {
    try {
      // En-têtes du CSV
      const headers = [
        'ID',
        'Nom',
        'Prénom',
        'Email',
        'Téléphone',
        'Adresse de départ',
        'Adresse d\'arrivée',
        'Date',
        'Heure',
        'Statut',
        'Prix',
        'Date de création'
      ].join(',');

      // Données des réservations
      const csvData = filteredBookings.map(booking => {
        return [
          booking.id,
          booking.lastName,
          booking.firstName,
          booking.email,
          booking.phone,
          booking.pickup?.address || 'N/A',
          booking.dropoff?.address || 'N/A',
          booking.scheduledDate,
          booking.scheduledTime,
          booking.status,
          booking.price,
          booking.formattedCreatedAt
        ].map(field => `"${field}"`).join(',');
      });

      // Combiner les en-têtes et les données
      const csv = [headers, ...csvData].join('\n');

      // Créer un blob et le télécharger
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `reservations_${format(new Date(), 'yyyy-MM-dd_HH-mm')}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Erreur lors de l\'exportation CSV:', error);
      toast.error('Erreur lors de l\'exportation');
    }
  };

  const handleDeleteBooking = async (id: string) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette réservation ?')) {
      try {
        await deleteDoc(doc(db, 'reservations', id));
        toast.success('Réservation supprimée avec succès');
      } catch (error) {
        console.error('Erreur lors de la suppression de la réservation:', error);
        toast.error('Erreur lors de la suppression de la réservation');
      }
    }
  };

  const updateBookingStatus = async (id: string, newStatus: string) => {
    try {
      await updateDoc(doc(db, 'reservations', id), {
        status: newStatus,
        updatedAt: serverTimestamp()
      });
      
      toast.success('Statut mis à jour avec succès');
      
      // Ajouter une notification
      setNotifications(prev => [{
        id: Date.now().toString(),
        message: `Réservation #${id.slice(0, 6)} mise à jour : ${newStatus}`,
        read: false
      }, ...prev]);
    } catch (error) {
      toast.error('Erreur lors de la mise à jour du statut');
    }
  };

  const calculateStats = (bookingsData: Booking[]) => {
    const stats = {
      totalBookings: bookingsData.length,
      pendingBookings: bookingsData.filter(b => b.status === 'pending').length,
      confirmedBookings: bookingsData.filter(b => b.status === 'confirmed').length,
      cancelledBookings: bookingsData.filter(b => b.status === 'cancelled').length,
      completedBookings: bookingsData.filter(b => b.status === 'completed').length,
      totalRevenue: bookingsData.reduce((acc, b) => acc + (b.price || 0), 0),
      averagePrice: 0,
      totalPassengers: bookingsData.reduce((acc, b) => acc + (b.passengers || 0), 0),
      averageDistance: 0,
      paymentMethodStats: {},
      vehicleTypeStats: bookingsData.reduce((acc, b) => {
        const type = b.vehicleType || 'unknown';
        acc[type] = (acc[type] || 0) + 1;
        return acc;
      }, {} as Record<string, number>)
    };

    // Calculer le prix moyen en excluant les réservations annulées
    const validBookings = bookingsData.filter(b => b.status !== 'cancelled' && b.price);
    stats.averagePrice = validBookings.length > 0 ? validBookings.reduce((sum, b) => sum + b.price, 0) / validBookings.length : 0;

    setStats(stats);
  };

  useEffect(() => {
    // Filtrer les réservations en fonction du terme de recherche et du statut
    const filtered = bookings.filter(booking => {
      const matchesSearch = 
        (booking.firstName?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
        (booking.lastName?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
        (booking.email?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
        (booking.phone || '').includes(searchTerm) ||
        (booking.pickup?.address?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
        (booking.dropoff?.address?.toLowerCase() || '').includes(searchTerm.toLowerCase());

      const matchesStatus = statusFilter === 'all' || booking.status === statusFilter;

      return matchesSearch && matchesStatus;
    });

    setFilteredBookings(filtered);
  }, [bookings, searchTerm, statusFilter]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Répartition des Réservations par Statut',
      },
    },
  };

  const chartData = {
    labels: ['En attente', 'Confirmées', 'Annulées', 'Terminées'],
    datasets: [
      {
        label: 'Nombre de Réservations',
        data: [
          filteredBookings.filter(booking => booking.status === 'pending').length,
          filteredBookings.filter(booking => booking.status === 'confirmed').length,
          filteredBookings.filter(booking => booking.status === 'cancelled').length,
          filteredBookings.filter(booking => booking.status === 'completed').length,
        ],
        backgroundColor: [
          'rgba(255, 206, 86, 0.5)',
          'rgba(75, 192, 192, 0.5)',
          'rgba(255, 99, 132, 0.5)',
          'rgba(54, 162, 235, 0.5)'
        ],
        borderColor: [
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)'
        ],
        borderWidth: 1,
      },
    ],
  };

  // Fonction utilitaire pour formater la date de manière sécurisée
  const formatSafeDate = (dateString: any): string => {
    try {
      const date = convertFirestoreTimestamp(dateString);
      if (!date) return 'Date invalide';
      return format(date, 'dd/MM/yyyy HH:mm', { locale: fr });
    } catch {
      return 'Date invalide';
    }
  };

  const calculateBookingStats = (bookings: Booking[]) => {
    const currentDate = new Date();
    const thirtyDaysAgo = subDays(currentDate, 30);

    const recentBookings = bookings.filter(booking => 
      new Date(booking.scheduledDate) >= thirtyDaysAgo && new Date(booking.scheduledDate) <= currentDate
    );

    const totalBookings = recentBookings.length;
    const completedBookings = recentBookings.filter(booking => booking.status === 'completed').length;
    const pendingBookings = recentBookings.filter(booking => booking.status === 'pending').length;
    const cancelledBookings = recentBookings.filter(booking => booking.status === 'cancelled').length;

    const completionRate = totalBookings > 0 ? (completedBookings / totalBookings) * 100 : 0;
    const cancellationRate = totalBookings > 0 ? (cancelledBookings / totalBookings) * 100 : 0;

    // Calcul du revenu total
    const totalRevenue = recentBookings.reduce((sum, booking) => {
      return sum + (booking.price || 0);
    }, 0);

    // Moyenne quotidienne des réservations
    const dailyAverage = totalBookings / 30;

    return {
      totalBookings,
      completedBookings,
      pendingBookings,
      cancelledBookings,
      completionRate: Math.round(completionRate * 10) / 10,
      cancellationRate: Math.round(cancellationRate * 10) / 10,
      totalRevenue,
      dailyAverage: Math.round(dailyAverage * 10) / 10
    };
  };

  const filterBookings = useCallback((bookings: Booking[], searchTerm: string, statusFilter: string) => {
    return bookings.filter(booking => {
      const matchesSearch = 
        (booking.firstName?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
        (booking.lastName?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
        (booking.email?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
        (booking.phone || '').includes(searchTerm) ||
        (booking.pickup?.address?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
        (booking.dropoff?.address?.toLowerCase() || '').includes(searchTerm.toLowerCase());

      const matchesStatus = statusFilter === 'all' || booking.status === statusFilter;

      return matchesSearch && matchesStatus;
    });
  }, []);

  const filteredBookingsMemo = useMemo(() => {
    return filterBookings(bookings, searchTerm, statusFilter);
  }, [bookings, searchTerm, statusFilter, filterBookings]);

  const bookingStats = useMemo(() => {
    const now = new Date();
    const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
    
    const recentBookings = filteredBookingsMemo.filter(booking => {
      return booking.createdAt && booking.createdAt >= thirtyDaysAgo;
    });

    const totalRevenue = recentBookings.reduce((acc, booking) => acc + (booking.price || 0), 0);
    const dailyAverage = totalRevenue / 30;

    return {
      totalBookings: recentBookings.length,
      confirmedBookings: recentBookings.filter(b => b.status === 'confirmed').length,
      cancelledBookings: recentBookings.filter(b => b.status === 'cancelled').length,
      totalRevenue,
      dailyAverage: Math.round(dailyAverage * 100) / 100
    };
  }, [filteredBookingsMemo]);

  // Fonction pour ajouter une alerte
  const addAlert = (type: 'success' | 'warning' | 'error', message: string) => {
    const newAlert = {
      id: Date.now().toString(),
      type,
      message
    };
    setAlerts(prev => [...prev, newAlert]);
    setTimeout(() => {
      setAlerts(prev => prev.filter(alert => alert.id !== newAlert.id));
    }, 5000);
  };

  // Effet pour simuler des alertes en temps réel
  useEffect(() => {
    const interval = setInterval(() => {
      const randomEvent = Math.random();
      if (randomEvent > 0.7) {
        addAlert('success', 'Nouvelle réservation confirmée');
      } else if (randomEvent > 0.4) {
        addAlert('warning', 'Chauffeur en retard de 5 minutes');
      }
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  const handleFilterChange = (newFilters: any) => {
    setFilters(newFilters);
  };

  const handleCloseFilters = () => {
    setShowFilters(false);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="p-4">
        {/* Header avec recherche et actions rapides */}
        <div className="mb-6">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-bold text-gray-900">Elite Transfert Admin</h1>
            <div className="flex items-center gap-4">
              <div className="relative">
                <button 
                  className="p-2 rounded-full hover:bg-gray-100"
                  onClick={() => setShowFilters(!showFilters)}
                >
                  <Filter className="w-5 h-5 text-gray-600" />
                </button>
                {notifications.filter(n => !n.read).length > 0 && (
                  <NotificationBadge count={notifications.filter(n => !n.read).length} />
                )}
              </div>
              <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center">
                <User className="w-5 h-5 text-blue-600" />
              </div>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <GlobalSearch value={searchTerm} onChange={setSearchTerm} />
            <QuickActions />
          </div>
        </div>

        {/* Filtres avancés avec animation */}
        <AnimatePresence>
          {showFilters && (
            <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
              <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
                <AdvancedFilters
                  filters={filters}
                  onFilterChange={handleFilterChange}
                  onClose={handleCloseFilters}
                />
              </div>
            </div>
          )}
        </AnimatePresence>

        {/* Nouveau sélecteur de date */}
        <div className="mb-6">
          <FloatingCalendar
            selected={selectedDate}
            onSelect={setSelectedDate}
            onClose={() => setSelectedDate(undefined)}
          />
        </div>

        {/* Statistiques détaillées */}
        <DetailedStats stats={mockStats} />

        {/* Quick Stats */}
        <QuickStats stats={bookingStats} />

        {/* Upcoming Bookings and Analytics */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
          {/* Upcoming Bookings */}
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Prochaines courses</h2>
              <button className="text-blue-600 hover:text-blue-900 text-sm font-medium">
                Voir tout
              </button>
            </div>
            <div className="space-y-4">
              {filteredBookingsMemo
                .filter(booking => new Date(booking.scheduledDate) >= new Date())
                .sort((a, b) => new Date(a.scheduledDate).getTime() - new Date(b.scheduledDate).getTime())
                .slice(0, 4)
                .map(booking => (
                  <div key={booking.id} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0 h-10 w-10 bg-gray-100 rounded-full flex items-center justify-center">
                        <Car className="h-5 w-5 text-gray-600" />
                      </div>
                      <div>
                        <p className="font-medium">{booking.firstName || ''} {booking.lastName || ''}</p>
                        <p className="text-sm text-gray-600">
                          {booking.pickup?.address || 'Départ non spécifié'} → 
                          {booking.dropoff?.address || 'Arrivée non spécifiée'}
                        </p>
                      </div>
                    </div>
                    <div className="text-right">
                      <p className="font-medium">{booking.formattedCreatedAt}</p>
                      <p className="text-sm text-gray-500">{booking.scheduledTime}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {/* Analytics Chart */}
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Analyse des revenus</h2>
              <select 
                className="text-sm border-gray-200 rounded-md p-2"
                defaultValue="week"
                onChange={(e) => console.log('Period changed:', e.target.value)}
              >
                <option value="week">7 derniers jours</option>
                <option value="month">30 derniers jours</option>
                <option value="year">Cette année</option>
              </select>
            </div>
            <div className="h-[300px] relative">
              <Line
                data={{
                  labels: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
                  datasets: [
                    {
                      label: 'Revenus',
                      data: [1200, 1900, 1500, 2100, 1800, 2500, 2200],
                      borderColor: 'rgb(59, 130, 246)',
                      backgroundColor: 'rgba(59, 130, 246, 0.1)',
                      pointBackgroundColor: 'rgb(59, 130, 246)',
                      pointBorderColor: '#fff',
                      pointBorderWidth: 2,
                      pointRadius: 4,
                      pointHoverRadius: 6,
                      fill: true,
                      tension: 0.4,
                    }
                  ]
                }}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  interaction: {
                    intersect: false,
                    mode: 'index'
                  },
                  plugins: {
                    legend: {
                      display: false
                    },
                    tooltip: {
                      enabled: true,
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                      titleColor: '#fff',
                      bodyColor: '#fff',
                      padding: 12,
                      displayColors: false,
                      callbacks: {
                        label: (context) => formatPrice(context.parsed.y)
                      }
                    }
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      grid: {
                        color: 'rgba(0, 0, 0, 0.05)',
                        drawBorder: false
                      },
                      ticks: {
                        callback: (value) => formatPrice(value as number),
                        font: {
                          size: 11
                        }
                      }
                    },
                    x: {
                      grid: {
                        display: false
                      },
                      ticks: {
                        font: {
                          size: 11
                        }
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>

        {/* Bookings Table */}
        <div className="bg-white rounded-lg shadow-md overflow-hidden mb-6">
          <div className="p-4 border-b flex justify-between items-center">
            <h2 className="text-lg font-semibold">Réservations</h2>
            <PDFErrorBoundary>
              <div className="relative">
                {filteredBookings.length > 0 ? (
                  <div className="inline-block">
                    <PDFDownloadLink
                      document={<BookingPDF bookings={filteredBookings} />}
                      fileName={`elite-transfert-reservations-${format(new Date(), 'dd-MM-yyyy')}.pdf`}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      {({ blob, url, loading, error }) => 
                        loading ? 'Chargement...' : 'Télécharger PDF'
                      }
                    </PDFDownloadLink>
                  </div>
                ) : (
                  <button
                    disabled
                    className="text-gray-500 hover:text-gray-700"
                  >
                    Aucune réservation
                  </button>
                )}
              </div>
            </PDFErrorBoundary>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Client</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Contact</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Trajet</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date & Heure</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Prix</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Statut</th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredBookingsMemo.map((booking) => (
                  <tr key={booking.id} className="hover:bg-gray-50 transition-colors">
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10 bg-gray-100 rounded-full flex items-center justify-center">
                          <User className="h-5 w-5 text-gray-600" />
                        </div>
                        <div className="ml-4">
                          <p className="font-medium">{booking.firstName || ''} {booking.lastName || ''}</p>
                          <p className="text-sm text-gray-600">
                            {booking.email ? (
                              <div className="flex items-center">
                                <Mail className="w-4 h-4 mr-1" />
                                {booking.email}
                              </div>
                            ) : null}
                            {!booking.email && !booking.phone && (
                              <span className="text-yellow-600">Aucun contact</span>
                            )}
                          </p>
                          <div className="text-xs text-gray-400 mt-1">
                            <Clock className="w-3 h-3 inline mr-1" />
                            {booking.formattedCreatedAt}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm">
                        {booking.pickup?.address ? (
                          <div className="flex items-center text-gray-900">
                            <MapPin className="w-4 h-4 mr-1" />
                            {booking.pickup.address}
                          </div>
                        ) : (
                          <span className="text-yellow-600">Départ non spécifié</span>
                        )}
                        {booking.dropoff?.address ? (
                          <div className="flex items-center mt-1 text-gray-900">
                            <MapPin className="w-4 h-4 mr-1" />
                            {booking.dropoff.address}
                          </div>
                        ) : (
                          <span className="text-yellow-600">Arrivée non spécifiée</span>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm">
                        {booking.scheduledDate ? (
                          <div className="flex items-center text-gray-900">
                            <Calendar className="w-4 h-4 mr-1" />
                            {booking.scheduledDate}
                          </div>
                        ) : (
                          <span className="text-yellow-600">Date non spécifiée</span>
                        )}
                        {booking.scheduledTime ? (
                          <div className="flex items-center mt-1 text-gray-900">
                            <Clock className="w-4 h-4 mr-1" />
                            {booking.scheduledTime}
                          </div>
                        ) : (
                          <span className="text-yellow-600">Heure non spécifiée</span>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(booking.status || 'pending')}`}>
                        {getStatusText(booking.status || 'pending')}
                      </span>
                      {(!booking.pickup?.address || !booking.dropoff?.address || !booking.scheduledDate || !booking.scheduledTime) && (
                        <span className="mt-1 block text-xs text-yellow-600">
                          Informations incomplètes
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {booking.vehicle?.name ? (
                        <span className="text-gray-900">{booking.vehicle.name}</span>
                      ) : (
                        <span className="text-yellow-600">Véhicule non spécifié</span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {booking.passengers || 0}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {booking.luggage || 0}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {typeof booking.price === 'number' ? (
                        <span className="text-gray-900 font-medium">{booking.price}€</span>
                      ) : (
                        <span className="text-yellow-600">Prix non défini</span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex space-x-2 justify-end">
                        <PDFErrorBoundary>
                          <PDFDownloadLink
                            document={<ReservationPDF booking={booking} />}
                            fileName={`reservation-${booking.id}.pdf`}
                            className="text-blue-600 hover:text-blue-900"
                          >
                            {({ blob, url, loading, error }) => 
                              loading ? 'Chargement...' : 'Télécharger PDF'
                            }
                          </PDFDownloadLink>
                        </PDFErrorBoundary>
                        <button
                          onClick={() => handleViewDetails(booking)}
                          className="text-blue-600 hover:text-blue-900"
                        >
                          Voir détails
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="p-4 border-t">
            <div className="text-sm text-gray-600">
              Total des réservations : {filteredBookingsMemo.length}
            </div>
          </div>
        </div>

        {/* Advanced Filters */}
        <div className="mb-6">
          <AdvancedFilters filters={filters} setFilters={setFilters} />
        </div>

        {/* Centre d'alertes */}
        <AlertCenter alerts={alerts} />

        {/* KPIs */}
        <KPIDashboard stats={mockStats.kpis} />

        {/* Statistiques financières */}
        <FinancialStats data={mockStats.financial} />

        {/* Carte des trajets */}
        <LiveMap bookings={filteredBookings} />

        {/* Calendrier des réservations */}
        <BookingCalendar bookings={filteredBookings} />

        {/* Analyses avancées */}
        <AdvancedAnalytics data={mockStats} />
      </div>
    </div>
  );
};

export default Eliteadmin92;

interface AdvancedFiltersProps {
  filters: {
    status: string[];
    dateRange: {
      start: Date | null;
      end: Date | null;
    };
    priceRange: {
      min: number;
      max: number;
    };
    sortBy: string;
  };
  setFilters: (filters: any) => void;
}

const AdvancedFilters: React.FC<AdvancedFiltersProps> = ({
  filters,
  setFilters
}) => {
  const statusOptions = [
    { value: 'all', label: 'Tous' },
    { value: 'pending', label: 'En attente' },
    { value: 'confirmed', label: 'Confirmé' },
    { value: 'cancelled', label: 'Annulé' },
    { value: 'completed', label: 'Terminé' }
  ];

  const handleStatusChange = (value: string) => {
    setFilters({
      ...filters,
      status: value === 'all' ? [] : [value]
    });
  };

  const handleDateChange = (type: 'start' | 'end', date: Date | null) => {
    setFilters({
      ...filters,
      dateRange: {
        ...filters.dateRange,
        [type]: date
      }
    });
  };

  const handlePriceChange = (type: 'min' | 'max', value: number) => {
    setFilters({
      ...filters,
      priceRange: {
        ...filters.priceRange,
        [type]: value
      }
    });
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold">Filtres avancés</h3>
        <button
          onClick={() => setFilters({
            status: [],
            dateRange: {
              start: null,
              end: null
            },
            priceRange: {
              min: 0,
              max: 1000
            },
            sortBy: 'date'
          })}
          className="text-gray-500 hover:text-gray-700"
        >
          <X className="h-5 w-5" />
        </button>
      </div>

      <div className="space-y-4">
        {/* Statut */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Statut
          </label>
          <div className="flex flex-wrap gap-2">
            {statusOptions.map(option => (
              <button
                key={option.value}
                onClick={() => handleStatusChange(option.value)}
                className={`px-4 py-2 rounded-full text-sm ${
                  (option.value === 'all' && filters.status.length === 0) ||
                  filters.status.includes(option.value)
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>

        {/* Plage de dates */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Période
          </label>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm text-gray-500 mb-1">Du</label>
              <input
                type="date"
                value={filters.dateRange.start?.toISOString().split('T')[0] || ''}
                onChange={(e) => handleDateChange('start', e.target.value ? new Date(e.target.value) : null)}
                className="w-full border rounded-lg px-3 py-2"
              />
            </div>
            <div>
              <label className="block text-sm text-gray-500 mb-1">Au</label>
              <input
                type="date"
                value={filters.dateRange.end?.toISOString().split('T')[0] || ''}
                onChange={(e) => handleDateChange('end', e.target.value ? new Date(e.target.value) : null)}
                className="w-full border rounded-lg px-3 py-2"
              />
            </div>
          </div>
        </div>

        {/* Plage de prix */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Prix (€)
          </label>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm text-gray-500 mb-1">Min</label>
              <input
                type="number"
                value={filters.priceRange.min}
                onChange={(e) => handlePriceChange('min', Number(e.target.value))}
                className="w-full border rounded-lg px-3 py-2"
              />
            </div>
            <div>
              <label className="block text-sm text-gray-500 mb-1">Max</label>
              <input
                type="number"
                value={filters.priceRange.max}
                onChange={(e) => handlePriceChange('max', Number(e.target.value))}
                className="w-full border rounded-lg px-3 py-2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

class PDFErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Erreur dans le composant PDF:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="p-4 rounded-lg bg-red-50 text-red-600">
          <h3 className="font-semibold mb-2">Erreur de chargement du PDF</h3>
          <p>Impossible de charger le document PDF. Veuillez réessayer plus tard.</p>
          <button
            onClick={() => this.setState({ hasError: false })}
            className="mt-2 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
          >
            Réessayer
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}
