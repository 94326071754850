import React from 'react';

export default function ServiceCorporate() {
  return (
    <article className="prose lg:prose-xl mx-auto py-8">
      <h1>Service Corporate</h1>
      <p>Elite Transfer propose des solutions de transport sur mesure pour les entreprises. Que ce soit pour des réunions, des conférences ou des événements d'entreprise, nous assurons un service professionnel et fiable.</p>
      <h2>Ce que nous offrons</h2>
      <ul>
        <li>Gestion des transports pour événements</li>
        <li>Chauffeurs expérimentés et discrets</li>
        <li>Véhicules adaptés à vos besoins</li>
        <li>Facturation simplifiée</li>
      </ul>
      <p>Faites confiance à Elite Transfer pour vos besoins en transport d'entreprise.</p>
    </article>
  );
}
