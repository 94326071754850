import React from 'react';

export default function EvenementsSpeciaux() {
  return (
    <article className="prose lg:prose-xl mx-auto py-8">
      <h1>Évènements Spéciaux</h1>
      <p>Pour vos évènements spéciaux, Elite Transfer vous propose un service de transport de qualité. Mariages, soirées, ou célébrations, voyagez avec classe et confort.</p>
      <h2>Pourquoi nous choisir ?</h2>
      <ul>
        <li>Service personnalisé pour chaque événement</li>
        <li>Véhicules élégants et confortables</li>
        <li>Chauffeurs professionnels et attentionnés</li>
        <li>Disponibilité et flexibilité</li>
      </ul>
      <p>Contactez-nous pour organiser votre transport lors de votre prochain événement spécial.</p>
    </article>
  );
}
