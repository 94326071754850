import { initializeApp, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyApOXofS_kdyt5GbJz5ynl5LdDi2nQN-Mg",
  authDomain: "elite-transfert.firebaseapp.com",
  projectId: "elite-transfert",
  storageBucket: "elite-transfert.firebasestorage.app",
  messagingSenderId: "864370147342",
  appId: "1:864370147342:web:43c3e68a51bfc111cbbc5f",
  measurementId: "G-CRVBQB6PTY"
};

// Initialiser Firebase seulement s'il n'est pas déjà initialisé
const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];

export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app, 'europe-west1');
export const analytics = typeof window !== 'undefined' ? getAnalytics(app) : null;