import { motion } from 'framer-motion';
import { Car, Crown, Bus } from 'lucide-react';
import type { Vehicle } from '../types';
import { useBookingStore } from '../store/bookingStore';

const vehicles: Vehicle[] = [
  {
    id: '1',
    type: 'standard',
    name: 'Mercedes Classe E',
    capacity: 4,
    basePrice: 5,
    pricePerKm: 2,
    image: 'https://apptitude-chauffeur.com/wp-content/uploads/2021/10/Classe-e-apptitude-chauffeurs.png'
  },
  {
    id: '2',
    type: 'luxury',
    name: 'Mercedes Classe S',
    capacity: 4,
    basePrice: 10,
    pricePerKm: 2.8,
    image: 'https://s3-eu-west-1.amazonaws.com/staticeu.izmocars.com/toolkit/commonassets/2024/24mercedesbenz/24mercedesbenzs580elphevamgln4wdsd7b/24mercedesbenzs580elphevamgln4wdsd7b_animations/colorpix/fr/640x480/mercedesbenz_24s580elphevamgln4wdsd7b_onyxzwartmetaalkleur_angular-front.webp'
  },
  {
    id: '3',
    type: 'minibus',
    name: 'Mercedes Classe V',
    capacity: 8,
    basePrice: 15,
    pricePerKm: 3.2,
    image: 'https://e7.pngegg.com/pngimages/925/695/png-clipart-mercedes-v-class-mercedes-benz-vito-mercedes-benz-s-class-car-mercedes-compact-car-van.png'
  }
];

export default function VehicleSelector() {
  const { selectedVehicle, setSelectedVehicle, price } = useBookingStore();

  const handleVehicleSelect = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle);
  };

  return (
    <div className="space-y-4">
      <h3 className="text-base font-medium text-neutral-900">Sélectionnez votre véhicule</h3>
      <div className="space-y-3">
        {vehicles.map((vehicle) => (
          <motion.button
            key={vehicle.id}
            type="button"
            onClick={() => handleVehicleSelect(vehicle)}
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.99 }}
            className={`w-full text-left transition-all duration-300 ${
              selectedVehicle?.id === vehicle.id
                ? 'ring-2 ring-amber-500 bg-amber-50'
                : 'hover:bg-neutral-50'
            } rounded-lg p-3 border border-neutral-200`}
          >
            <div className="flex items-center gap-3">
              <div className="relative w-16 sm:w-20 h-16 sm:h-20 rounded-md overflow-hidden flex-shrink-0">
                <img
                  src={vehicle.image}
                  alt={vehicle.name}
                  className="w-full h-full object-contain"
                />
              </div>
              
              <div className="flex-1 min-w-0">
                <div className="flex items-center justify-between mb-1">
                  <h4 className="text-sm font-medium text-neutral-900 truncate">{vehicle.name}</h4>
                  <div className={`p-1.5 rounded-full ${
                    selectedVehicle?.id === vehicle.id 
                      ? 'bg-amber-100 text-amber-600'
                      : 'bg-neutral-100 text-neutral-600'
                  }`}>
                    {vehicle.type === 'standard' && <Car className="w-4 h-4" />}
                    {vehicle.type === 'luxury' && <Crown className="w-4 h-4" />}
                    {vehicle.type === 'minibus' && <Bus className="w-4 h-4" />}
                  </div>
                </div>
                
                <div className="text-xs text-neutral-600 space-y-1">
                  <div className="flex justify-between">
                    <span>Prix de base:</span>
                    <span className="font-medium">{vehicle.basePrice}€</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Prix par km:</span>
                    <span className="font-medium">{vehicle.pricePerKm}€</span>
                  </div>
                  {selectedVehicle?.id === vehicle.id && price && (
                    <div className="pt-1 mt-1 border-t border-neutral-200">
                      <div className="flex justify-between text-amber-600 font-medium">
                        <span>Prix total:</span>
                        <span>{price}€</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </motion.button>
        ))}
      </div>
    </div>
  );
}