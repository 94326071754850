import { Dialog } from '@headlessui/react';
import { X, MapPin, Mail, Phone, User, Briefcase, Users, MessageSquare, Calendar, Clock } from 'lucide-react';
import { useState } from 'react';
import { useBookingStore } from '../store/bookingStore';
import { createBooking } from '../services/bookingService';
import { sendBookingConfirmation } from '../utils/emailService';
import toast from 'react-hot-toast';

interface BookingConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  scheduledDate: string;
  scheduledTime: string;
}

export default function BookingConfirmationModal({ isOpen, onClose, scheduledDate, scheduledTime }: BookingConfirmationModalProps) {
  const { pickup, dropoff, selectedVehicle, price } = useBookingStore();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    passengers: '1',
    luggage: '0',
    notes: '',
    needsBabySeats: false,
    additionalNotes: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validate required fields
    if (!formData.firstName.trim()) {
      toast.error('Le prénom est requis');
      return;
    }
    if (!formData.lastName.trim()) {
      toast.error('Le nom est requis');
      return;
    }
    if (!formData.email.trim()) {
      toast.error('L\'email est requis');
      return;
    }
    if (!formData.phone.trim()) {
      toast.error('Le téléphone est requis');
      return;
    }
    if (!formData.passengers) {
      toast.error('Le nombre de passagers est requis');
      return;
    }

    setIsSubmitting(true);
    
    try {
      if (!pickup || !dropoff || !selectedVehicle) {
        throw new Error('Informations de réservation manquantes');
      }

      const bookingData = {
        ...formData,
        pickup,
        dropoff,
        vehicle: selectedVehicle,
        price,
        passengers: parseInt(formData.passengers),
        luggage: parseInt(formData.luggage),
        scheduledDate,
        scheduledTime,
      };

      // Créer la réservation dans Firebase
      const bookingId = await createBooking(bookingData);

      // Envoyer l'email de confirmation
      console.log('Tentative d\'envoi d\'email avec les données:', {
        client_name: `${formData.firstName} ${formData.lastName}`,
        client_email: formData.email,
        date: scheduledDate,
        time: scheduledTime,
        pickup_location: pickup.address,
        dropoff_location: dropoff.address,
        vehicle_type: selectedVehicle.name,
        price: price,
        phone_number: "01 23 45 67 89"
      });

      const emailResult = await sendBookingConfirmation({
        client_name: `${formData.firstName} ${formData.lastName}`,
        client_email: formData.email,
        date: scheduledDate,
        time: scheduledTime,
        pickup_location: pickup.address,
        dropoff_location: dropoff.address,
        vehicle_type: selectedVehicle.name,
        price: price,
        phone_number: "01 23 45 67 89" // Votre numéro de service client
      });

      console.log('Résultat de l\'envoi d\'email:', emailResult);

      if (emailResult.success) {
        toast.success('Votre réservation a été confirmée ! Un email de confirmation vous a été envoyé.');
      } else {
        console.error('Erreur envoi email:', emailResult.error);
        toast.success('Votre réservation a été confirmée ! L\'email de confirmation sera envoyé prochainement.');
      }

      onClose();
    } catch (error) {
      toast.error('Erreur lors de la confirmation de la réservation');
      console.error('Booking error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.checked
    }));
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/60 backdrop-blur-sm" aria-hidden="true" />
      
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white shadow-2xl transition-all">
            {/* Header */}
            <div className="relative bg-blue-600 px-6 py-8 text-center text-white">
              <button 
                onClick={onClose} 
                className="absolute right-4 top-4 text-white/80 hover:text-white transition-colors"
              >
                <X className="w-6 h-6" />
              </button>
              <Dialog.Title className="text-3xl font-bold">
                Finaliser votre réservation
              </Dialog.Title>
              <p className="mt-2 text-blue-100">
                Plus qu'une étape pour confirmer votre trajet
              </p>
            </div>

            <form onSubmit={handleSubmit} className="p-6">
              <div className="space-y-8">
                {/* Récapitulatif du trajet */}
                <div className="bg-blue-50 rounded-xl p-4 space-y-3">
                  <div className="flex items-center justify-between pb-3 border-b border-blue-100">
                    <div className="flex items-center gap-3">
                      <div className="p-2 bg-blue-100 rounded-lg">
                        <Calendar className="w-5 h-5 text-blue-600" />
                      </div>
                      <div>
                        <p className="text-sm text-blue-600 font-medium">{scheduledDate}</p>
                        <p className="text-sm text-blue-600">{scheduledTime}</p>
                      </div>
                    </div>
                    <div className="text-right">
                      <p className="text-sm text-blue-600 font-medium">{selectedVehicle?.name}</p>
                      <p className="text-lg font-bold text-blue-600">{price}€</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-3">
                    <div className="p-2 bg-blue-100 rounded-lg">
                      <MapPin className="w-5 h-5 text-blue-600" />
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-900">Départ</p>
                      <p className="text-sm text-gray-600">{pickup?.address}</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-3">
                    <div className="p-2 bg-blue-100 rounded-lg">
                      <MapPin className="w-5 h-5 text-blue-600" />
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-900">Arrivée</p>
                      <p className="text-sm text-gray-600">{dropoff?.address}</p>
                    </div>
                  </div>
                </div>

                {/* Informations personnelles */}
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold text-gray-900">
                    Vos informations
                  </h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                        Prénom
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <User className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="text"
                          id="firstName"
                          name="firstName"
                          required
                          value={formData.firstName}
                          onChange={handleChange}
                          className="block w-full pl-10 pr-3 py-3 border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                          placeholder="John"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                        Nom
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <User className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="text"
                          id="lastName"
                          name="lastName"
                          required
                          value={formData.lastName}
                          onChange={handleChange}
                          className="block w-full pl-10 pr-3 py-3 border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                          placeholder="Doe"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <Mail className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          required
                          value={formData.email}
                          onChange={handleChange}
                          className="block w-full pl-10 pr-3 py-3 border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                          placeholder="john.doe@example.com"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                        Téléphone
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <Phone className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="tel"
                          id="phone"
                          name="phone"
                          required
                          value={formData.phone}
                          onChange={handleChange}
                          className="block w-full pl-10 pr-3 py-3 border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                          placeholder="+33 6 12 34 56 78"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Détails supplémentaires */}
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold text-gray-900">
                    Détails du voyage
                  </h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="passengers" className="block text-sm font-medium text-gray-700">
                        Nombre de passagers
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <Users className="h-5 w-5 text-gray-400" />
                        </div>
                        <select
                          id="passengers"
                          name="passengers"
                          required
                          value={formData.passengers}
                          onChange={handleChange}
                          className="block w-full pl-10 pr-3 py-3 border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                        >
                          {[1, 2, 3, 4, 5, 6, 7, 8].map(num => (
                            <option key={num} value={num}>{num} passager{num > 1 ? 's' : ''}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div>
                      <label htmlFor="luggage" className="block text-sm font-medium text-gray-700">
                        Nombre de bagages
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <Briefcase className="h-5 w-5 text-gray-400" />
                        </div>
                        <select
                          id="luggage"
                          name="luggage"
                          value={formData.luggage}
                          onChange={handleChange}
                          className="block w-full pl-10 pr-3 py-3 border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                        >
                          {[0, 1, 2, 3, 4].map(num => (
                            <option key={num} value={num}>{num} bagage{num > 1 ? 's' : ''}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
                      Notes supplémentaires
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute top-3 left-3 pointer-events-none">
                        <MessageSquare className="h-5 w-5 text-gray-400" />
                      </div>
                      <textarea
                        id="notes"
                        name="notes"
                        rows={3}
                        value={formData.notes}
                        onChange={handleChange}
                        placeholder="Instructions spéciales, besoins particuliers..."
                        className="block w-full pl-10 pr-3 py-3 border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="flex items-center space-x-3">
                      <input
                        type="checkbox"
                        id="needsBabySeats"
                        name="needsBabySeats"
                        checked={formData.needsBabySeats}
                        onChange={handleCheckboxChange}
                        className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                      />
                      <span className="text-gray-700">Siège bébé requis</span>
                    </label>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Notes supplémentaires
                    </label>
                    <textarea
                      id="additionalNotes"
                      name="additionalNotes"
                      rows={3}
                      value={formData.additionalNotes}
                      onChange={handleChange}
                      placeholder="Informations complémentaires pour votre trajet..."
                      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>
                </div>
              </div>

              {/* Footer */}
              <div className="mt-8 pt-6 border-t border-gray-200 flex justify-end gap-4">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-6 py-3 text-sm font-medium text-gray-700 hover:text-gray-900 transition-colors"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-8 py-3 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors shadow-lg hover:shadow-xl"
                >
                  {isSubmitting ? 'Confirmation...' : 'Valider la réservation'}
                </button>
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}