import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, doc, updateDoc, where, deleteDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { MessageCircle, Check, Clock, User, Bot, Search, Filter, Trash2, Archive, Star, Flag } from 'lucide-react';

interface Conversation {
  id: string;
  userName: string;
  userEmail: string;
  lastMessage: string;
  lastMessageTimestamp: any;
  status: 'active' | 'closed';
  agentName: string;
  agentRole: string;
  messagesCount: number;
  createdAt: any;
  updatedAt: any;
}

interface ChatMessage {
  id: string;
  content: string;
  isBot: boolean;
  timestamp: any;
  sessionId: string;
  status: 'read' | 'unread';
  userName: string;
  userEmail: string;
  agentName?: string;
  agentRole?: string;
}

const ChatManagement: React.FC = () => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [selectedConversation, setSelectedConversation] = useState<string | null>(null);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Charger les conversations
    const conversationsQuery = query(
      collection(db, 'conversations'),
      orderBy('updatedAt', 'desc')
    );

    const unsubscribe = onSnapshot(conversationsQuery, (snapshot) => {
      const conversationsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Conversation[];
      
      setConversations(conversationsData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Charger les messages de la conversation sélectionnée
    if (selectedConversation) {
      const messagesQuery = query(
        collection(db, 'chat_messages'),
        where('sessionId', '==', selectedConversation),
        orderBy('timestamp', 'asc')
      );

      const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
        const messagesData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as ChatMessage[];
        
        setMessages(messagesData);
      });

      return () => unsubscribe();
    }
  }, [selectedConversation]);

  const formatDate = (timestamp: any) => {
    if (!timestamp) return 'Date inconnue';
    return new Date(timestamp.seconds * 1000).toLocaleString();
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Liste des conversations */}
      <div className="w-1/3 border-r bg-white">
        <div className="p-4 border-b">
          <h2 className="text-lg font-semibold">Conversations</h2>
          <div className="mt-2">
            <div className="relative">
              <input
                type="text"
                placeholder="Rechercher..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <Search className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
          </div>
        </div>

        <div className="overflow-y-auto h-[calc(100vh-120px)]">
          {loading ? (
            <div className="p-4 text-center text-gray-500">Chargement...</div>
          ) : conversations.length === 0 ? (
            <div className="p-4 text-center text-gray-500">Aucune conversation</div>
          ) : (
            conversations
              .filter(conv => 
                conv.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                conv.lastMessage.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map(conversation => (
                <div
                  key={conversation.id}
                  onClick={() => setSelectedConversation(conversation.id)}
                  className={`p-4 border-b cursor-pointer hover:bg-gray-50 ${
                    selectedConversation === conversation.id ? 'bg-blue-50' : ''
                  }`}
                >
                  <div className="flex items-center justify-between">
                    <div>
                      <h3 className="font-medium">{conversation.userName}</h3>
                      <p className="text-sm text-gray-500">{conversation.userEmail}</p>
                    </div>
                    <span className="text-xs text-gray-500">
                      {formatDate(conversation.lastMessageTimestamp)}
                    </span>
                  </div>
                  <p className="mt-1 text-sm text-gray-600 truncate">
                    {conversation.lastMessage}
                  </p>
                  <div className="mt-1 flex items-center gap-2">
                    <span className="text-xs text-gray-500">
                      {conversation.messagesCount} messages
                    </span>
                    <span className="text-xs text-gray-500">•</span>
                    <span className="text-xs text-gray-500">
                      {conversation.agentName}
                    </span>
                  </div>
                </div>
              ))
          )}
        </div>
      </div>

      {/* Détails de la conversation */}
      <div className="flex-1 bg-white">
        {selectedConversation ? (
          <div className="h-full flex flex-col">
            <div className="p-4 border-b">
              <h2 className="text-lg font-semibold">
                {conversations.find(c => c.id === selectedConversation)?.userName}
              </h2>
            </div>

            <div className="flex-1 overflow-y-auto p-4 space-y-4">
              {messages.map(message => (
                <div
                  key={message.id}
                  className={`flex ${message.isBot ? 'justify-start' : 'justify-end'}`}
                >
                  <div
                    className={`max-w-[70%] p-3 rounded-lg ${
                      message.isBot
                        ? 'bg-gray-100'
                        : 'bg-blue-500 text-white'
                    }`}
                  >
                    <p>{message.content}</p>
                    <div className="mt-1 text-xs text-gray-500 flex items-center gap-2">
                      <span>{formatDate(message.timestamp)}</span>
                      {message.isBot && (
                        <span className="flex items-center gap-1">
                          <Bot className="w-3 h-3" />
                          {message.agentName}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="h-full flex items-center justify-center text-gray-500">
            Sélectionnez une conversation pour voir les détails
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatManagement;
