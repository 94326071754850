import { AI_CONFIG, SYSTEM_PROMPT } from '../config/aiConfig';

const DEFAULT_RESPONSES = {
  ERROR: "Je suis désolé, je rencontre des difficultés techniques. Je peux vous mettre en relation avec un agent humain si vous le souhaitez.",
  LOADING: "Je suis en train de réfléchir à votre demande. Pourriez-vous patienter un instant ?",
  FALLBACK: "Je comprends votre demande. Pour vous assurer la meilleure réponse possible, je vous suggère de contacter notre service client au numéro affiché sur notre site.",
  RATE_LIMIT: "Je reçois beaucoup de demandes en ce moment. Pourriez-vous réessayer dans quelques instants ?"
};

// Gestionnaire de file d'attente
class RequestQueue {
  private queue: Array<() => Promise<void>> = [];
  private processing = false;
  private lastRequestTime = 0;
  private readonly minInterval = 5000; // 5 secondes entre les requêtes
  
  async add<T>(request: () => Promise<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      this.queue.push(async () => {
        try {
          const result = await this.executeWithBackoff(request);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      });
      
      if (!this.processing) {
        this.processQueue();
      }
    });
  }
  
  private async executeWithBackoff(request: () => Promise<any>, attempt = 1): Promise<any> {
    const now = Date.now();
    const timeToWait = Math.max(0, this.minInterval - (now - this.lastRequestTime));
    
    if (timeToWait > 0) {
      await new Promise(resolve => setTimeout(resolve, timeToWait));
    }
    
    try {
      this.lastRequestTime = Date.now();
      return await request();
    } catch (error: any) {
      if (error?.status === 429 && attempt < 3) {
        const backoffTime = Math.pow(2, attempt) * 1000;
        await new Promise(resolve => setTimeout(resolve, backoffTime));
        return this.executeWithBackoff(request, attempt + 1);
      }
      throw error;
    }
  }
  
  private async processQueue() {
    if (this.processing || this.queue.length === 0) return;
    
    this.processing = true;
    
    while (this.queue.length > 0) {
      const request = this.queue.shift();
      if (request) {
        await request();
      }
    }
    
    this.processing = false;
  }
}

const requestQueue = new RequestQueue();

export async function getAIResponse(userMessage: string): Promise<string> {
  try {
    const makeRequest = async () => {
      const response = await fetch(AI_CONFIG.API_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${AI_CONFIG.API_KEY}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: AI_CONFIG.MODEL,
          messages: [
            {
              role: "system",
              content: SYSTEM_PROMPT
            },
            {
              role: "user",
              content: userMessage
            }
          ],
          max_tokens: AI_CONFIG.MAX_TOKENS,
          temperature: AI_CONFIG.TEMPERATURE,
          presence_penalty: AI_CONFIG.PRESENCE_PENALTY,
          frequency_penalty: AI_CONFIG.FREQUENCY_PENALTY
        }),
      });

      if (!response.ok) {
        const error: any = new Error(`HTTP error! status: ${response.status}`);
        error.status = response.status;
        throw error;
      }

      const data = await response.json();
      
      if (!data.choices || !data.choices[0]?.message?.content) {
        return DEFAULT_RESPONSES.FALLBACK;
      }

      return data.choices[0].message.content.trim();
    };

    return await requestQueue.add(makeRequest);
  } catch (error) {
    console.error('Erreur AI Service:', error);
    return DEFAULT_RESPONSES.ERROR;
  }
}
