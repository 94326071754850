import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import HomePage from '../pages/HomePage';
import BookingPage from '../pages/BookingPage';
import TransfertsAeroport from '../pages/TransfertsAeroport';
import LocationAvecChauffeur from '../pages/LocationAvecChauffeur';
import ServiceCorporate from '../pages/ServiceCorporate';
import EvenementsSpeciaux from '../pages/EvenementsSpeciaux';
import PWAInstallPrompt from './PWAInstallPrompt';

const Layout: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col bg-[#121212]">
      <Navbar />
      <main className="flex-grow pt-16">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/booking" element={<BookingPage />} />
          <Route path="/transferts-aeroport" element={<TransfertsAeroport />} />
          <Route path="/location-avec-chauffeur" element={<LocationAvecChauffeur />} />
          <Route path="/service-corporate" element={<ServiceCorporate />} />
          <Route path="/evenements-speciaux" element={<EvenementsSpeciaux />} />
        </Routes>
      </main>
      <Footer />
      <PWAInstallPrompt />
    </div>
  );
};

export default Layout;
