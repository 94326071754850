import emailjs from '@emailjs/browser';

// Initialisation d'EmailJS
emailjs.init('_yxr_4k4q1N2ozuJT');

interface BookingConfirmationData {
    client_name: string;
    date: string;
    time: string;
    pickup_location: string;
    dropoff_location: string;
    vehicle_type: string;
    price: number;
    phone_number: string;
    client_email: string;
}

interface BookingData {
    firstName: string;
    lastName: string;
    email: string;
    pickup: {
        address: string;
    };
    dropoff: {
        address: string;
    };
    vehicle: {
        name: string;
    };
    price: number;
    scheduledDate: string;
    scheduledTime: string;
}

const SERVICE_ID = 'service_a5iuc9w';
const CONFIRMATION_TEMPLATE_ID = 'template_nuiphap'; // Vous devrez créer ce template dans EmailJS

export const sendBookingConfirmation = async (bookingData: BookingConfirmationData) => {
    try {
        const response = await emailjs.send(
            SERVICE_ID,
            CONFIRMATION_TEMPLATE_ID,
            {
                client_name: bookingData.client_name,
                date: bookingData.date,
                time: bookingData.time,
                pickup_location: bookingData.pickup_location,
                dropoff_location: bookingData.dropoff_location,
                vehicle_type: bookingData.vehicle_type,
                price: `${bookingData.price}`,
                phone_number: bookingData.phone_number,
                client_email: bookingData.client_email
            }
        );

        console.log('Email de confirmation envoyé:', response);
        return { success: true, response };
    } catch (error) {
        console.error('Erreur lors de l\'envoi de l\'email de confirmation:', error);
        return { success: false, error };
    }
};

export const sendBookingConfirmationFromAdmin = async (booking: BookingData) => {
    try {
        const response = await emailjs.send(
            SERVICE_ID,
            CONFIRMATION_TEMPLATE_ID,
            {
                client_name: `${booking.firstName} ${booking.lastName}`,
                client_email: booking.email,
                date: booking.scheduledDate,
                time: booking.scheduledTime,
                pickup_location: booking.pickup.address,
                dropoff_location: booking.dropoff.address,
                vehicle_type: booking.vehicle.name,
                price: `${booking.price}`,
                phone_number: "01 23 45 67 89"
            }
        );

        console.log('Email de confirmation envoyé:', response);
        return { success: true, response };
    } catch (error) {
        console.error('Erreur lors de l\'envoi de l\'email de confirmation:', error);
        return { success: false, error };
    }
};
