import { useState } from 'react';
import { Calendar, Clock, Plane, MapPin, ArrowRight, Info } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useBookingStore } from '../store/bookingStore';
import VehicleSelector from './VehicleSelector';
import LocationInput from './LocationInput';
import BookingConfirmationModal from './BookingConfirmationModal';
import toast from 'react-hot-toast';
import { lazy, Suspense } from 'react';

const airports = [
  { name: 'Paris Charles de Gaulle (CDG)', coordinates: [2.5479, 49.0097] },
  { name: 'Paris Orly (ORY)', coordinates: [2.3795, 48.7262] },
  { name: 'Paris Beauvais (BVA)', coordinates: [2.1136, 49.4544] }
];

const MapComponent = lazy(() => import('./Map'));

export default function BookingForm() {
  const [showAirports, setShowAirports] = useState(false);
  const [selectedAirport, setSelectedAirport] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [scheduledDate, setScheduledDate] = useState('');
  const [scheduledTime, setScheduledTime] = useState('');
  const [showTooltip, setShowTooltip] = useState('');
  
  const { 
    pickup, 
    dropoff, 
    selectedVehicle,
    price,
    setPickup,
    setDropoff
  } = useBookingStore();

  const minDate = new Date().toISOString().split('T')[0];

  const handleAirportSelection = (airport: typeof airports[0]) => {
    if (selectedAirport === airport.name) {
      setSelectedAirport('');
      setDropoff(null);
    } else {
      setSelectedAirport(airport.name);
      setDropoff({ address: airport.name, coordinates: airport.coordinates });
    }
    setShowAirports(false);
  };

  return (
    <div className="w-full">
      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="grid grid-cols-1 lg:grid-cols-2 min-h-[600px]">
          {/* Carte */}
          <div className="h-[300px] lg:h-full w-full">
            <div className="w-full h-full">
              <Suspense fallback={
                <div className="w-full h-[400px] bg-gray-100 rounded-xl flex items-center justify-center">
                  <div className="animate-pulse flex flex-col items-center">
                    <div className="w-12 h-12 bg-gray-200 rounded-full mb-2"></div>
                    <div className="text-gray-500">Chargement de la carte...</div>
                  </div>
                </div>
              }>
                <MapComponent className="h-full w-full" />
              </Suspense>
            </div>
          </div>

          {/* Formulaire */}
          <div className="p-6 lg:p-8 lg:max-h-[800px] lg:overflow-y-auto">
            <form onSubmit={(e) => {
              e.preventDefault();
              if (!pickup || !dropoff || !selectedVehicle || !scheduledDate || !scheduledTime) {
                toast.error('Veuillez remplir tous les champs obligatoires');
                return;
              }
              setShowConfirmationModal(true);
            }} className="space-y-6">
              {/* Points de départ et d'arrivée */}
              <div className="space-y-4">
                <div className="relative">
                  <label className="block text-sm font-medium text-neutral-700 mb-1">
                    Point de départ
                  </label>
                  <LocationInput
                    label=""
                    placeholder="Adresse de départ"
                    onLocationSelect={setPickup}
                    value={pickup?.address}
                    className="w-full px-4 py-3 border border-neutral-300 rounded-lg focus:ring-2 focus:ring-amber-500 focus:border-transparent"
                  />
                  <div className="absolute right-3 top-9">
                    <button type="button" className="text-amber-600 hover:text-amber-700">
                      <span className="sr-only">Sélectionner sur la carte</span>
                      <MapPin className="w-5 h-5" />
                    </button>
                  </div>
                </div>

                <div className="relative">
                  <label className="block text-sm font-medium text-neutral-700 mb-1">
                    Point d'arrivée
                  </label>
                  {!selectedAirport ? (
                    <div>
                      <button
                        onClick={() => setShowAirports(!showAirports)}
                        className="w-full text-left"
                      >
                        <LocationInput
                          label=""
                          placeholder="Adresse d'arrivée"
                          onLocationSelect={setDropoff}
                          value={dropoff?.address}
                          className="w-full px-4 py-3 border border-neutral-300 rounded-lg focus:ring-2 focus:ring-amber-500 focus:border-transparent"
                        />
                      </button>
                      <AnimatePresence>
                        {showAirports && (
                          <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            className="absolute z-20 w-full mt-1 bg-white rounded-xl border border-neutral-200 shadow-xl"
                          >
                            {airports.map((airport) => (
                              <motion.button
                                key={airport.name}
                                whileHover={{ scale: 1.02, backgroundColor: '#F3F4F6' }}
                                onClick={() => handleAirportSelection(airport)}
                                className="w-full p-4 text-left text-neutral-700 first:rounded-t-xl last:rounded-b-xl flex items-center gap-3 group"
                              >
                                <div className="w-10 h-10 rounded-full bg-amber-50 flex items-center justify-center group-hover:scale-110 transition-transform">
                                  <Plane className="w-5 h-5 text-amber-600" />
                                </div>
                                <span className="font-medium">{airport.name}</span>
                              </motion.button>
                            ))}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  ) : (
                    <LocationInput
                      label=""
                      placeholder="Adresse d'arrivée"
                      onLocationSelect={setDropoff}
                      value={dropoff?.address}
                      className="w-full px-4 py-3 border border-neutral-300 rounded-lg focus:ring-2 focus:ring-amber-500 focus:border-transparent"
                    />
                  )}
                  <div className="absolute right-3 top-9">
                    <button type="button" className="text-amber-600 hover:text-amber-700">
                      <span className="sr-only">Sélectionner sur la carte</span>
                      <MapPin className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              </div>

              {/* Date et Heure */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-neutral-700 mb-1">
                    Date
                  </label>
                  <div className="relative">
                    <input
                      type="date"
                      value={scheduledDate}
                      onChange={(e) => setScheduledDate(e.target.value)}
                      min={minDate}
                      required
                      className="w-full px-4 py-3 border border-neutral-300 rounded-lg focus:ring-2 focus:ring-amber-500 focus:border-transparent text-neutral-900 bg-white"
                    />
                    <Calendar className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-neutral-400 pointer-events-none" />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-neutral-700 mb-1">
                    Heure
                  </label>
                  <div className="relative">
                    <input
                      type="time"
                      value={scheduledTime}
                      onChange={(e) => setScheduledTime(e.target.value)}
                      required
                      className="w-full px-4 py-3 border border-neutral-300 rounded-lg focus:ring-2 focus:ring-amber-500 focus:border-transparent text-neutral-900 bg-white"
                    />
                    <Clock className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-neutral-400 pointer-events-none" />
                  </div>
                </div>
              </div>

              {/* Véhicule */}
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-neutral-900">
                  Véhicule
                </h3>
                <VehicleSelector />
              </div>

              {/* Bouton de réservation */}
              <button
                type="submit"
                className="w-full bg-amber-600 text-white py-4 px-6 rounded-lg hover:bg-amber-700 transition-colors duration-300 font-semibold text-lg shadow-lg hover:shadow-xl disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Réserver maintenant
              </button>

              {price && (
                <motion.p 
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="text-center text-lg"
                >
                  Prix estimé: <span className="font-bold text-amber-600">{price}€</span>
                </motion.p>
              )}
            </form>
          </div>
        </div>
      </div>

      <BookingConfirmationModal
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        scheduledDate={scheduledDate}
        scheduledTime={scheduledTime}
        pickup={pickup}
        dropoff={dropoff}
      />
    </div>
  );
}