import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { User, Settings, LogOut } from 'lucide-react';
import { useAuthStore } from '../store/authStore';
import { auth } from '../lib/firebase';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

const UserMenu = () => {
  const { user } = useAuthStore();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/admin/login');
      toast.success('Déconnexion réussie');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
      toast.error('Erreur lors de la déconnexion');
    }
  };

  return (
    <Menu as="div" className="relative">
      <Menu.Button className="flex items-center gap-2 px-3 py-2 rounded-lg hover:bg-white/10 transition-colors">
        <div className="w-8 h-8 rounded-full bg-indigo-600 flex items-center justify-center">
          <User className="w-5 h-5 text-white" />
        </div>
        {user && (
          <div className="hidden md:block text-left">
            <div className="text-sm font-medium text-white">{user.email}</div>
            <div className="text-xs text-gray-400">Administrateur</div>
          </div>
        )}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right rounded-xl bg-gray-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-white/10">
          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => navigate('/admin/profile')}
                  className={`${
                    active ? 'bg-white/10' : ''
                  } group flex w-full items-center rounded-lg px-2 py-2 text-sm text-white`}
                >
                  <Settings className="mr-2 h-5 w-5" aria-hidden="true" />
                  Paramètres du compte
                </button>
              )}
            </Menu.Item>
          </div>

          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={handleLogout}
                  className={`${
                    active ? 'bg-white/10' : ''
                  } group flex w-full items-center rounded-lg px-2 py-2 text-sm text-red-500`}
                >
                  <LogOut className="mr-2 h-5 w-5" aria-hidden="true" />
                  Se déconnecter
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default UserMenu;
