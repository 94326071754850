import React from 'react';

export default function TransfertsAeroport() {
  return (
    <article className="prose lg:prose-xl mx-auto py-8">
      <h1>Transferts Aéroport</h1>
      <p>Profitez d'un service de transfert aéroportuaire fiable et confortable avec Elite Transfer. Nos chauffeurs professionnels vous garantissent un voyage en toute sécurité et ponctualité vers ou depuis l'aéroport.</p>
      <h2>Pourquoi choisir notre service ?</h2>
      <ul>
        <li>Chauffeurs professionnels et expérimentés</li>
        <li>Véhicules de luxe et bien entretenus</li>
        <li>Service disponible 24/7</li>
        <li>Réservation facile en ligne</li>
      </ul>
      <p>Réservez dès maintenant et voyagez l'esprit tranquille avec Elite Transfer.</p>
    </article>
  );
}
