export const generateBookingNumber = () => {
  const prefix = 'RES';
  const timestamp = Date.now().toString();
  const random = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
  return `${prefix}${timestamp}${random}`;
};

export const formatBookingNumber = (bookingNumber: string) => {
  return bookingNumber.replace(/(\w{3})(\d{13})(\d{3})/, '$1-$2-$3');
};
