import React, { useState, useEffect, useCallback, useRef } from 'react';
import { collection, query, where, onSnapshot, updateDoc, doc, deleteDoc, orderBy, limit, startAfter, getDocs, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { Engine } from "tsparticles-engine";
import { Calendar, Clock, MapPin, Phone, Mail, User, Users, Briefcase, Car, Check, X, Download, MoreHorizontal, UserPlus, Shield, MessageCircle, Trash2, Search, ArrowUp, ArrowDown, CheckCircle, Euro, Loader, Filter, Baby, Map, DollarSign, Sun, Moon, Grid, CalendarDays, Edit } from 'lucide-react';
import * as XLSX from 'xlsx';
import { toast, Toaster } from 'react-hot-toast';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../lib/firebase';
import { sendBookingConfirmationFromAdmin } from '../utils/emailService';
import UserMenu from '../components/UserMenu';
import { useTheme } from '../context/ThemeContext';
import BookingCalendar from '../components/BookingCalendar';
import BookingContextMenu from '../components/BookingContextMenu';
import NotificationCenter from '../components/NotificationCenter';
import useKeyboardShortcuts from '../hooks/useKeyboardShortcuts';

// Types et interfaces existants...
interface Coordinates {
  lat: number;
  lng: number;
}

interface Location {
  address: string;
  coordinates: Coordinates | null;
}

interface Vehicle {
  name: string;
  type: string;
  image?: string;
}

interface BookingOptions {
  babySeat: boolean;
  multipleStops: boolean;
  luggageService: boolean;
}

interface Booking {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  pickup: Location | null;
  dropoff: Location | null;
  scheduledDate: string;
  scheduledTime: string;
  status: 'pending' | 'confirmed' | 'completed' | 'cancelled';
  price: number;
  vehicle: Vehicle;
  passengers: number;
  luggage: number;
  createdAt: Date | null;
  additionalInfo?: string;
  bookingNumber?: string;
  options?: BookingOptions;
}

interface Admin {
  id: string;
  email: string;
  createdAt: Date | null;
  lastLogin?: Date;
  role: 'admin' | 'superadmin';
}

interface FilterOptions {
  dateRange: { start: Date | null; end: Date | null };
  priceRange: { min: number | null; max: number | null };
  status: string[];
  location: string;
  passengers: number | null;
}

const AdminDashboardNew = () => {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<'reservations' | 'admins' | 'conversations'>('reservations');
  const [viewMode, setViewMode] = useState<'grid' | 'calendar'>('grid');
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [selectedStatus, setSelectedStatus] = useState<string>('all');
  const [actionLoading, setActionLoading] = useState<string>('');
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [newAdminData, setNewAdminData] = useState({
    email: '',
    password: '',
    role: 'admin' as 'admin' | 'superadmin'
  });
  const [currentUserRole, setCurrentUserRole] = useState<string>('');
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const [dateRange, setDateRange] = useState<{ start: string; end: string }>({
    start: '',
    end: ''
  });
  const [priceRange, setPriceRange] = useState<{ min: string; max: string }>({
    min: '',
    max: ''
  });
  const [contextMenu, setContextMenu] = useState<{ isOpen: boolean; position: { x: number; y: number }; bookingId: string }>({
    isOpen: false,
    position: { x: 0, y: 0 },
    bookingId: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [lastVisible, setLastVisible] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const observerTarget = useRef(null);
  const { theme, toggleTheme } = useTheme();

  const [dashboardStats, setDashboardStats] = useState({
    totalBookings: 0,
    todayBookings: 0,
    weekRevenue: 0,
    pendingBookings: 0
  });

  const [advancedFilters, setAdvancedFilters] = useState<FilterOptions>({
    dateRange: { start: null, end: null },
    priceRange: { min: null, max: null },
    status: [],
    location: '',
    passengers: null
  });

  const shortcuts = [
    { key: 'n', ctrlKey: true, action: () => setShowAdminModal(true), description: 'Nouvel administrateur' },
    { key: 'f', ctrlKey: true, action: () => setIsFiltersVisible(true), description: 'Afficher les filtres' },
    { key: 'r', ctrlKey: true, action: () => refreshData(), description: 'Rafraîchir les données' },
    { key: 'p', ctrlKey: true, action: () => exportData(), description: 'Exporter les données' },
  ];

  const { getShortcutsList } = useKeyboardShortcuts(shortcuts);

  const loadBookings = useCallback(async () => {
    setIsLoading(true);
    const pageSize = 10;
    
    let q = query(
      collection(db, 'bookings'),
      orderBy('createdAt', 'desc'),
      limit(pageSize)
    );

    if (lastVisible) {
      q = query(
        collection(db, 'bookings'),
        orderBy('createdAt', 'desc'),
        startAfter(lastVisible),
        limit(pageSize)
      );
    }

    const unsubscribe = onSnapshot(q, (snapshot) => {
      if (!snapshot.empty) {
        const newBookings = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || null
        }));

        setBookings(prev => [...prev, ...newBookings]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      }
      setIsLoading(false);
    });

    return unsubscribe;
  }, [lastVisible]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && !isLoading) {
          loadBookings();
        }
      },
      { threshold: 0.5 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => observer.disconnect();
  }, [loadBookings, isLoading]);

  const handleContextMenu = (e: React.MouseEvent, bookingId: string) => {
    e.preventDefault();
    setContextMenu({
      isOpen: true,
      position: { x: e.clientX, y: e.clientY },
      bookingId
    });
  };

  const handleContextAction = async (action: string) => {
    const booking = bookings.find(b => b.id === contextMenu.bookingId);
    if (!booking) return;

    switch (action) {
      case 'edit':
        handleEditBooking(booking);
        break;
      case 'status':
        handleStatusChange(booking.id, booking.status === 'pending' ? 'confirmed' : 'cancelled');
        break;
      case 'message':
        // Implémenter l'envoi de message
        break;
      case 'export':
        // Implémenter l'export
        break;
      case 'delete':
        await handleDeleteBooking(booking.id);
        break;
    }

    setContextMenu({ isOpen: false, position: { x: 0, y: 0 }, bookingId: '' });
  };

  const refreshData = () => {
    setBookings([]);
    setLastVisible(null);
    setCurrentPage(1);
    loadBookings();
  };

  useEffect(() => {
    const q = query(collection(db, 'bookings'), orderBy('createdAt', 'desc'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const bookingsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
        scheduledDate: doc.data().scheduledDate || new Date()
      }));
      setBookings(bookingsData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const loadAdmins = async () => {
      const adminsRef = collection(db, 'admins');
      const q = query(adminsRef, orderBy('createdAt', 'desc'));
      
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const adminList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || null,
          lastLogin: doc.data().lastLogin?.toDate() || null
        })) as Admin[];
        setAdmins(adminList);
      });

      return unsubscribe;
    };

    loadAdmins();
  }, []);

  useEffect(() => {
    const checkUserRole = async () => {
      const user = auth.currentUser;
      if (user) {
        const adminRef = collection(db, 'admins');
        const q = query(adminRef, where('email', '==', user.email));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const adminData = querySnapshot.docs[0].data();
          setCurrentUserRole(adminData.role);
        }
      }
    };
    checkUserRole();
  }, []);

  const calculateDashboardStats = useCallback(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const todayBookings = bookings.filter(booking => {
      const bookingDate = new Date(booking.scheduledDate);
      bookingDate.setHours(0, 0, 0, 0);
      return bookingDate.getTime() === today.getTime();
    }).length;

    const weekStart = new Date();
    weekStart.setDate(weekStart.getDate() - 7);
    
    const weekRevenue = bookings
      .filter(booking => new Date(booking.scheduledDate) >= weekStart)
      .reduce((sum, booking) => sum + booking.price, 0);

    const pendingBookings = bookings.filter(booking => booking.status === 'pending').length;

    setDashboardStats({
      totalBookings: bookings.length,
      todayBookings,
      weekRevenue,
      pendingBookings
    });
  }, [bookings]);

  const applyAdvancedFilters = useCallback((filters: FilterOptions) => {
    setAdvancedFilters(filters);
    
    let filteredQuery = query(collection(db, 'bookings'), orderBy('createdAt', 'desc'));
    
    // Appliquer les filtres à la requête Firestore
    if (filters.dateRange.start) {
      filteredQuery = query(filteredQuery, where('scheduledDate', '>=', filters.dateRange.start));
    }
    if (filters.dateRange.end) {
      filteredQuery = query(filteredQuery, where('scheduledDate', '<=', filters.dateRange.end));
    }
    if (filters.status.length > 0) {
      filteredQuery = query(filteredQuery, where('status', 'in', filters.status));
    }
    if (filters.priceRange.min !== null) {
      filteredQuery = query(filteredQuery, where('price', '>=', filters.priceRange.min));
    }
    if (filters.priceRange.max !== null) {
      filteredQuery = query(filteredQuery, where('price', '<=', filters.priceRange.max));
    }

    // Mettre à jour les réservations avec les filtres
    const unsubscribe = onSnapshot(filteredQuery, (snapshot) => {
      const filteredBookings = snapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || null
        }))
        .filter(booking => {
          if (filters.location && !booking.pickup?.address.toLowerCase().includes(filters.location.toLowerCase())) {
            return false;
          }
          if (filters.passengers && booking.passengers !== filters.passengers) {
            return false;
          }
          return true;
        });

      setBookings(filteredBookings);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    calculateDashboardStats();
  }, [bookings, calculateDashboardStats]);

  const bookingStats = {
    total: bookings.length,
    pending: bookings.filter(b => b.status === 'pending').length,
    confirmed: bookings.filter(b => b.status === 'confirmed').length,
    completed: bookings.filter(b => b.status === 'completed').length,
    cancelled: bookings.filter(b => b.status === 'cancelled').length,
    totalRevenue: bookings.reduce((sum, b) => sum + b.price, 0)
  };

  const filteredAndSortedBookings = (() => {
    let filtered = bookings;

    if (selectedStatus !== 'all') {
      filtered = filtered.filter(booking => booking.status === selectedStatus);
    }

    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      filtered = filtered.filter(booking =>
        (booking.firstName?.toLowerCase() || '').includes(searchLower) ||
        (booking.lastName?.toLowerCase() || '').includes(searchLower) ||
        (booking.email?.toLowerCase() || '').includes(searchLower) ||
        (booking.phone || '').includes(searchTerm) ||
        (booking.pickup?.address?.toLowerCase() || '').includes(searchLower) ||
        (booking.dropoff?.address?.toLowerCase() || '').includes(searchLower)
      );
    }

    if (dateRange.start && dateRange.end) {
      filtered = filtered.filter(booking => {
        const bookingDate = booking.scheduledDate ? new Date(booking.scheduledDate) : null;
        return bookingDate && 
               bookingDate >= new Date(dateRange.start) && 
               bookingDate <= new Date(dateRange.end);
      });
    }

    if (priceRange.min || priceRange.max) {
      filtered = filtered.filter(booking => {
        const price = booking.price || 0;
        const min = priceRange.min ? parseFloat(priceRange.min) : 0;
        const max = priceRange.max ? parseFloat(priceRange.max) : Infinity;
        return price >= min && price <= max;
      });
    }

    return [...filtered].sort((a, b) => {
      if (bookingStats.sortBy === 'date') {
        const dateA = a.createdAt?.getTime() || 0;
        const dateB = b.createdAt?.getTime() || 0;
        return bookingStats.sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
      }
      if (bookingStats.sortBy === 'price') {
        const priceA = a.price || 0;
        const priceB = b.price || 0;
        return bookingStats.sortOrder === 'asc' ? priceA - priceB : priceB - priceA;
      }
      const nameA = `${a.firstName || ''} ${a.lastName || ''}`.toLowerCase();
      const nameB = `${b.firstName || ''} ${b.lastName || ''}`.toLowerCase();
      return bookingStats.sortOrder === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    });
  })();

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'pending': return 'bg-yellow-100 text-yellow-800';
      case 'confirmed': return 'bg-green-100 text-green-800';
      case 'completed': return 'bg-blue-100 text-blue-800';
      case 'cancelled': return 'bg-red-100 text-red-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  const handleEditBooking = async (booking: Booking) => {
    try {
      const bookingRef = doc(db, 'bookings', booking.id);
      
      // Afficher une confirmation avant de modifier
      const confirmEdit = window.confirm('Voulez-vous modifier cette réservation ?');
      
      if (confirmEdit) {
        // Mettre à jour le statut dans Firestore
        await updateDoc(bookingRef, {
          status: 'confirmed',
          updatedAt: new Date()
        });
        
        toast.success('Réservation modifiée avec succès');
      }
    } catch (error) {
      console.error('Erreur lors de la modification de la réservation:', error);
      toast.error('Erreur lors de la modification de la réservation');
    }
  };

  const handleStatusChange = async (bookingId: string, newStatus: 'confirmed' | 'cancelled') => {
    try {
      const bookingRef = doc(db, 'bookings', bookingId);
      
      // Afficher une confirmation avant de changer le statut
      const confirmChange = window.confirm(`Voulez-vous ${newStatus === 'confirmed' ? 'confirmer' : 'annuler'} cette réservation ?`);
      
      if (confirmChange) {
        // Mettre à jour le statut dans Firestore
        await updateDoc(bookingRef, {
          status: newStatus,
          updatedAt: new Date()
        });

        if (newStatus === 'confirmed') {
          // Récupérer les données de la réservation
          const bookingDoc = await getDoc(bookingRef);
          if (bookingDoc.exists()) {
            const bookingData = bookingDoc.data() as Booking;
            
            // Envoyer l'email de confirmation
            const emailResult = await sendBookingConfirmationFromAdmin(bookingData);
            
            if (emailResult.success) {
              toast.success('Réservation confirmée et email envoyé au client');
            } else {
              toast.success('Réservation confirmée, mais erreur lors de l\'envoi de l\'email');
              console.error('Erreur envoi email:', emailResult.error);
            }
          }
        } else {
          toast.success(`Réservation ${newStatus === 'cancelled' ? 'annulée' : 'mise à jour'} avec succès`);
        }
      }
    } catch (error) {
      console.error('Erreur lors de la modification du statut:', error);
      toast.error('Erreur lors de la modification du statut');
    }
  };

  const handleDeleteBooking = async (bookingId: string) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette réservation ?')) {
      try {
        setActionLoading(bookingId);
        const bookingRef = doc(db, 'bookings', bookingId);
        await deleteDoc(bookingRef);
        toast.success('Réservation supprimée avec succès');
      } catch (error) {
        console.error('Erreur lors de la suppression:', error);
        toast.error('Erreur lors de la suppression de la réservation');
      } finally {
        setActionLoading('');
      }
    }
  };

  const exportToExcel = () => {
    try {
      const exportData = filteredAndSortedBookings.map(booking => ({
        'ID': booking.id,
        'Statut': booking.status,
        'Date de création': booking.createdAt ? format(booking.createdAt, 'dd/MM/yyyy HH:mm', { locale: fr }) : '',
        'Prénom': booking.firstName,
        'Nom': booking.lastName,
        'Email': booking.email,
        'Téléphone': booking.phone,
        'Départ': booking.pickup?.address || 'Adresse non spécifiée',
        'Arrivée': booking.dropoff?.address || 'Adresse non spécifiée',
        'Date': booking.scheduledDate,
        'Heure': booking.scheduledTime,
        'Véhicule': booking.vehicle.name,
        'Passagers': booking.passengers,
        'Bagages': booking.luggage,
        'Prix': booking.price,
        'Informations supplémentaires': booking.additionalInfo || ''
      }));

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(exportData);

      XLSX.utils.book_append_sheet(wb, ws, 'Réservations');

      const fileName = `reservations_${format(new Date(), 'dd-MM-yyyy_HH-mm')}.xlsx`;
      XLSX.writeFile(wb, fileName);

      toast.success('Export Excel généré avec succès');
    } catch (error) {
      console.error('Erreur lors de l\'export:', error);
      toast.error('Erreur lors de l\'export Excel');
    }
  };

  const handleCreateAdmin = async () => {
    if (!newAdminData.email.endsWith('@elitetransfert.com')) {
      toast.error('L\'email doit se terminer par @elitetransfert.com');
      return;
    }

    try {
      setActionLoading('creating-admin');
      
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        newAdminData.email,
        newAdminData.password
      );

      await addDoc(collection(db, 'admins'), {
        email: newAdminData.email,
        role: newAdminData.role,
        createdAt: new Date(),
        uid: userCredential.user.uid
      });

      toast.success('Administrateur créé avec succès');
      setShowAdminModal(false);
      setNewAdminData({
        email: '',
        password: '',
        role: 'admin'
      });
    } catch (error: any) {
      console.error('Erreur lors de la création de l\'admin:', error);
      toast.error(error.message || 'Erreur lors de la création du compte');
    } finally {
      setActionLoading('');
    }
  };

  const renderAdminSection = () => (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold">Gestion des administrateurs</h2>
        <button
          onClick={() => setShowAdminModal(true)}
          className="flex items-center gap-2 px-4 py-2 bg-indigo-600 hover:bg-indigo-700 rounded-lg transition-colors"
        >
          <UserPlus className="w-5 h-5" />
          Nouvel administrateur
        </button>
      </div>

      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {admins.map((admin) => (
          <div
            key={admin.id}
            className="backdrop-blur-md bg-white/10 rounded-xl p-6 hover:bg-white/20 transition-all"
          >
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="text-xl font-bold flex items-center gap-2">
                  <Shield className="w-5 h-5" />
                  {admin.email}
                </h3>
                <div className="mt-2 text-sm text-gray-400">
                  Rôle: {admin.role}
                </div>
              </div>
            </div>
            
            <div className="mt-4 text-sm text-gray-400">
              Créé le {admin.createdAt ? format(admin.createdAt, 'dd/MM/yyyy HH:mm', { locale: fr }) : 'N/A'}
            </div>
            {admin.lastLogin && (
              <div className="mt-1 text-sm text-gray-400">
                Dernière connexion: {format(admin.lastLogin, 'dd/MM/yyyy HH:mm', { locale: fr })}
              </div>
            )}
          </div>
        ))}
      </div>

      {showAdminModal && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-gray-900 rounded-xl p-6 w-full max-w-md">
            <h3 className="text-xl font-bold mb-4">Nouvel administrateur</h3>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  value={newAdminData.email}
                  onChange={(e) => setNewAdminData({ ...newAdminData, email: e.target.value })}
                  className="w-full px-4 py-2 bg-black/40 border border-white/10 rounded-lg text-white"
                  placeholder="admin@elitetransfert.com"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Mot de passe
                </label>
                <input
                  type="password"
                  value={newAdminData.password}
                  onChange={(e) => setNewAdminData({ ...newAdminData, password: e.target.value })}
                  className="w-full px-4 py-2 bg-black/40 border border-white/10 rounded-lg text-white"
                  placeholder="••••••••"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Rôle
                </label>
                <select
                  value={newAdminData.role}
                  onChange={(e) => setNewAdminData({ ...newAdminData, role: e.target.value as 'admin' | 'superadmin' })}
                  className="w-full px-4 py-2 bg-black/40 border border-white/10 rounded-lg text-white"
                >
                  <option value="admin">Administrateur</option>
                  <option value="superadmin">Super Administrateur</option>
                </select>
              </div>
            </div>

            <div className="flex justify-end gap-3 mt-6">
              <button
                onClick={() => setShowAdminModal(false)}
                className="px-4 py-2 text-gray-400 hover:text-white transition-colors"
              >
                Annuler
              </button>
              <button
                onClick={handleCreateAdmin}
                disabled={actionLoading === 'creating-admin'}
                className="px-4 py-2 bg-indigo-600 hover:bg-indigo-700 rounded-lg transition-colors flex items-center gap-2"
              >
                {actionLoading === 'creating-admin' ? (
                  <>
                    <div className="w-4 h-4 border-2 border-white/20 border-t-white rounded-full animate-spin"></div>
                    Création...
                  </>
                ) : (
                  <>
                    <UserPlus className="w-5 h-5" />
                    Créer
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const handleDayClick = (date: Date) => {
    const dateStr = format(date, 'yyyy-MM-dd');
    const dayBookings = bookings.filter(booking => booking.scheduledDate === dateStr);
    
    if (dayBookings.length > 0) {
      toast.success(`${dayBookings.length} réservation(s) le ${format(date, 'dd/MM/yyyy', { locale: fr })}`);
    } else {
      toast.info(`Aucune réservation le ${format(date, 'dd/MM/yyyy', { locale: fr })}`);
    }
  };

  const formatDate = (date: Date | string | null) => {
    if (!date) return '';
    try {
      const dateObj = typeof date === 'string' ? new Date(date) : date;
      return format(dateObj, 'dd MMMM yyyy', { locale: fr });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date invalide';
    }
  };

  const renderTabs = () => (
    <div className="flex space-x-4 mb-6">
      <button
        onClick={() => setActiveTab('reservations')}
        className={`px-4 py-2 rounded-lg transition-colors ${
          activeTab === 'reservations'
            ? 'bg-indigo-600 text-white'
            : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
        }`}
      >
        Réservations
      </button>
      {currentUserRole === 'superadmin' && (
        <button
          onClick={() => setActiveTab('admins')}
          className={`px-4 py-2 rounded-lg transition-colors ${
            activeTab === 'admins'
              ? 'bg-indigo-600 text-white'
              : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
          }`}
        >
          Gestion Admin
        </button>
      )}
      <button
        onClick={() => setActiveTab('conversations')}
        className={`px-4 py-2 rounded-lg transition-colors ${
          activeTab === 'conversations'
            ? 'bg-indigo-600 text-white'
            : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
        }`}
      >
        Conversations
      </button>
    </div>
  );

  const renderFilters = () => (
    <div className="space-y-4 sm:space-y-0 sm:flex sm:items-center sm:justify-between mb-6">
      <h1 className="text-2xl font-bold">Tableau de bord</h1>
      
      <div className="w-full sm:w-auto space-y-4 sm:space-y-0 sm:flex sm:items-center sm:gap-4">
        <div className="relative flex-1 sm:flex-none">
          <input
            type="text"
            placeholder="Rechercher..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-4 py-2 bg-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500/50 text-white placeholder-gray-400"
          />
          <Search className="absolute right-3 top-2.5 w-5 h-5 text-gray-400" />
        </div>

        <div className="flex flex-wrap gap-2">
          <select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className="px-3 py-2 bg-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500/50 text-white"
          >
            <option value="">Tous les statuts</option>
            <option value="pending">En attente</option>
            <option value="confirmed">Confirmé</option>
            <option value="completed">Terminé</option>
            <option value="cancelled">Annulé</option>
          </select>

          <select
            value={bookingStats.sortBy}
            onChange={(e) => bookingStats.sortBy = e.target.value}
            className="px-3 py-2 bg-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500/50 text-white"
          >
            <option value="date">Date</option>
            <option value="price">Prix</option>
            <option value="status">Statut</option>
          </select>
        </div>
      </div>
    </div>
  );

  const particlesInit = async (engine: Engine) => {
    console.log("Initializing particles...");
    try {
      await loadFull(engine);
    } catch (error) {
      console.error("Error initializing particles:", error);
    }
  };

  const particlesOptions = {
    particles: {
      number: {
        value: 50,
        density: {
          enable: true,
          value_area: 800
        }
      },
      color: {
        value: "#6366f1"
      },
      links: {
        enable: true,
        color: "#6366f1",
        opacity: 0.2
      },
      move: {
        enable: true,
        speed: 1
      },
      opacity: {
        value: 0.3
      }
    },
    background: {
      color: {
        value: "transparent"
      }
    }
  };

  const formatPrice = (price: number) => {
    return Number(price).toFixed(2);
  };

  return (
    <div className={`min-h-screen bg-gray-50 dark:bg-gray-900 transition-all duration-200 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={particlesOptions}
        className="absolute inset-0"
      />
      
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-8 gap-4">
          <h1 className="text-2xl font-bold">
            Tableau de bord administrateur
          </h1>
          
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              {activeTab === 'reservations' && (
                <button
                  onClick={exportToExcel}
                  className="flex items-center gap-2 px-4 py-2 bg-green-600 hover:bg-green-700 rounded-lg transition-colors"
                >
                  <Download className="w-5 h-5" />
                  Exporter Excel
                </button>
              )}
              
              <button
                onClick={toggleTheme}
                className="p-2 rounded-lg bg-white/10 hover:bg-white/20 transition-colors"
                aria-label="Toggle theme"
              >
                {theme === 'dark' ? (
                  <Sun className="w-5 h-5 text-yellow-500" />
                ) : (
                  <Moon className="w-5 h-5 text-blue-500" />
                )}
              </button>

              <div className="flex gap-2 bg-white/10 rounded-lg p-1">
                <button
                  onClick={() => setViewMode('grid')}
                  className={`p-2 rounded ${viewMode === 'grid' ? 'bg-white/20 text-white' : 'text-gray-400 hover:text-white'}`}
                >
                  <Grid className="w-5 h-5" />
                </button>
                <button
                  onClick={() => setViewMode('calendar')}
                  className={`p-2 rounded ${viewMode === 'calendar' ? 'bg-white/20 text-white' : 'text-gray-400 hover:text-white'}`}
                >
                  <CalendarDays className="w-5 h-5" />
                </button>
              </div>
              
              <UserMenu />
            </div>
          </div>
          {renderTabs()}
        </div>

        {activeTab === 'reservations' ? (
          <div>
            <div className="flex justify-between items-center mb-6">
              <div className="flex items-center gap-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                  <div className="bg-white/10 rounded-lg p-4">
                    <h3 className="text-lg font-bold mb-2">Réservations totales</h3>
                    <p className="text-3xl font-bold">{dashboardStats.totalBookings}</p>
                  </div>
                  <div className="bg-white/10 rounded-lg p-4">
                    <h3 className="text-lg font-bold mb-2">Réservations en attente</h3>
                    <p className="text-3xl font-bold">{dashboardStats.pendingBookings}</p>
                  </div>
                  <div className="bg-white/10 rounded-lg p-4">
                    <h3 className="text-lg font-bold mb-2">Réservations confirmées</h3>
                    <p className="text-3xl font-bold">{bookingStats.confirmed}</p>
                  </div>
                  <div className="bg-white/10 rounded-lg p-4">
                    <h3 className="text-lg font-bold mb-2">Chiffre d'affaires total</h3>
                    <p className="text-3xl font-bold">{formatPrice(dashboardStats.weekRevenue)}€</p>
                  </div>
                </div>
              </div>
            </div>

            {renderFilters()}

            {viewMode === 'grid' ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {filteredAndSortedBookings.map((booking) => (
                  <div
                    key={booking.id}
                    className="backdrop-blur-md bg-white/10 rounded-xl p-4 sm:p-6 hover:bg-white/20 transition-all h-full flex flex-col w-full"
                  >
                    <div className="flex items-start justify-between mb-4">
                      <div className="flex items-center gap-3">
                        <User className="w-5 h-5 text-blue-500 flex-shrink-0" />
                        <div>
                          <h3 className="text-lg font-semibold">
                            {booking.firstName} {booking.lastName}
                          </h3>
                          <p className="text-sm text-gray-400">{booking.bookingNumber || ''}</p>
                        </div>
                      </div>
                      <div className="flex flex-col items-end gap-2">
                        <span className={`px-3 py-1 rounded-full text-xs ${getStatusColor(booking.status)}`}>
                          {booking.status}
                        </span>
                        <div className="flex gap-1">
                          {booking.status === 'pending' && (
                            <>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleStatusChange(booking.id, 'confirmed');
                                }}
                                className="p-1.5 hover:bg-green-500/20 rounded-full transition-colors"
                                title="Confirmer"
                              >
                                <Check className="w-4 h-4 text-green-500" />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleStatusChange(booking.id, 'cancelled');
                                }}
                                className="p-1.5 hover:bg-red-500/20 rounded-full transition-colors"
                                title="Annuler"
                              >
                                <X className="w-4 h-4 text-red-500" />
                              </button>
                            </>
                          )}
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditBooking(booking);
                            }}
                            className="p-1.5 hover:bg-blue-500/20 rounded-full transition-colors"
                            title="Modifier"
                          >
                            <Edit className="w-4 h-4 text-blue-500" />
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteBooking(booking.id);
                            }}
                            className="p-1.5 hover:bg-red-500/20 rounded-full transition-colors"
                            title="Supprimer"
                          >
                            <Trash2 className="w-4 h-4 text-red-500" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="flex-1 space-y-4">
                      <div className="grid grid-cols-1 gap-2">
                        <div className="flex items-center gap-2">
                          <Mail className="w-5 h-5 text-indigo-400 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-300">Email</p>
                            <p className="text-white truncate">{booking.email}</p>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <Phone className="w-5 h-5 text-indigo-400 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-300">Téléphone</p>
                            <p className="text-white">{booking.phone}</p>
                          </div>
                        </div>
                      </div>

                      <div className="space-y-2">
                        <div className="flex items-start gap-2">
                          <MapPin className="w-5 h-5 mt-1 text-indigo-400 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-300">Départ</p>
                            <p className="truncate">{booking.pickup?.address || 'Adresse non spécifiée'}</p>
                          </div>
                        </div>
                        <div className="flex items-start gap-2 overflow-hidden">
                          <MapPin className="w-5 h-5 mt-1 text-indigo-400 flex-shrink-0" />
                          <div className="flex-1 min-w-0">
                            <p className="text-sm text-gray-300">Arrivée</p>
                            <p className="truncate">{booking.dropoff?.address || 'Adresse non spécifiée'}</p>
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-4">
                        <div className="flex items-center gap-2">
                          <Calendar className="w-5 h-5 text-indigo-400 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-300">Date</p>
                            <p>{formatDate(booking.scheduledDate)}</p>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <Clock className="w-5 h-5 text-indigo-400 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-300">Heure</p>
                            <p>{booking.scheduledTime}</p>
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-3 gap-2">
                        <div className="flex items-center gap-2">
                          <Car className="w-5 h-5 text-indigo-400 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-300">Véhicule</p>
                            <p className="truncate">{booking.vehicle?.name || 'Non spécifié'}</p>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <Users className="w-5 h-5 text-indigo-400 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-300">Passagers</p>
                            <p>{booking.passengers}</p>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <Briefcase className="w-5 h-5 text-indigo-400 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-300">Bagages</p>
                            <p>{booking.luggage}</p>
                          </div>
                        </div>
                      </div>

                      {booking.options && (
                        <div className="flex flex-wrap gap-2">
                          {booking.options.babySeat && (
                            <div className="flex items-center bg-blue-500/20 px-2 py-1 rounded-lg" title="Siège bébé (+10€)">
                              <Baby className="w-4 h-4 text-blue-500" />
                              <span className="ml-1 text-xs text-blue-500 hidden sm:inline">Siège bébé</span>
                            </div>
                          )}
                          {booking.options.multipleStops && (
                            <div className="flex items-center bg-purple-500/20 px-2 py-1 rounded-lg" title="Arrêts multiples (+15€)">
                              <Map className="w-4 h-4 text-purple-500" />
                              <span className="ml-1 text-xs text-purple-500 hidden sm:inline">Arrêts multiples</span>
                            </div>
                          )}
                          {booking.options.luggageService && (
                            <div className="flex items-center bg-green-500/20 px-2 py-1 rounded-lg" title="Service bagages (+20€)">
                              <Briefcase className="w-4 h-4 text-green-500" />
                              <span className="ml-1 text-xs text-green-500 hidden sm:inline">Service bagages</span>
                            </div>
                          )}
                        </div>
                      )}

                      {booking.additionalInfo && (
                        <div className="p-3 bg-white/5 rounded-lg">
                          <p className="text-sm text-gray-300">Informations supplémentaires :</p>
                          <p className="text-sm mt-1">{booking.additionalInfo}</p>
                        </div>
                      )}
                    </div>

                    <div className="mt-4 pt-4 border-t border-white/10">
                      <div className="flex justify-between items-center">
                        <span className="text-gray-300">Prix total</span>
                        <span className="text-xl font-semibold">{formatPrice(booking.price)}€</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <BookingCalendar 
                bookings={filteredAndSortedBookings} 
                onDayClick={handleDayClick}
              />
            )}
          </div>
        ) : activeTab === 'admins' ? (
          renderAdminSection()
        ) : (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold">Conversations</h2>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              {/* Liste des conversations */}
            </div>
          </div>
        )}

      </div>
      <Toaster position="top-right" />
      <BookingContextMenu
        isOpen={contextMenu.isOpen}
        position={contextMenu.position}
        onClose={() => setContextMenu({ isOpen: false, position: { x: 0, y: 0 }, bookingId: '' })}
        onAction={handleContextAction}
        bookingStatus={bookings.find(b => b.id === contextMenu.bookingId)?.status || ''}
      />
      <div className="fixed bottom-6 right-6 flex flex-col gap-2">
        <button
          onClick={refreshData}
          className="p-3 bg-blue-500 text-white rounded-full shadow-lg hover:bg-blue-600"
          title="Rafraîchir"
        >
          <ArrowUp className="w-5 h-5" />
        </button>
        <button
          onClick={() => setIsFiltersVisible(true)}
          className="p-3 bg-purple-500 text-white rounded-full shadow-lg hover:bg-purple-600"
          title="Filtres"
        >
          <Filter className="w-5 h-5" />
        </button>
      </div>
      <div className="fixed top-6 right-6">
        <NotificationCenter />
      </div>
      <div className="fixed bottom-6 left-6">
        <div className="stats-value text-2xl font-bold">
          {formatPrice(dashboardStats.weekRevenue)}€
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardNew;
