import React from 'react';

export default function LocationAvecChauffeur() {
  return (
    <article className="prose lg:prose-xl mx-auto py-8">
      <h1>Location avec Chauffeur</h1>
      <p>Découvrez le confort et le luxe de voyager avec notre service de location avec chauffeur. Que ce soit pour des affaires ou des loisirs, nous vous offrons un service personnalisé et haut de gamme.</p>
      <h2>Nos avantages</h2>
      <ul>
        <li>Chauffeurs professionnels et courtois</li>
        <li>Véhicules de prestige</li>
        <li>Flexibilité et discrétion</li>
        <li>Service sur mesure</li>
      </ul>
      <p>Contactez-nous pour planifier votre prochain voyage avec style et confort.</p>
    </article>
  );
}
