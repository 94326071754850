import { httpsCallable } from 'firebase/functions';
import { functions } from '../lib/firebase';

export const sendConfirmationEmail = async (bookingData: any) => {
  try {
    const sendEmail = httpsCallable(functions, 'sendBookingConfirmation');
    const result = await sendEmail(bookingData);
    const response = result.data as { success: boolean; error?: string };
    
    if (!response.success) {
      throw new Error(response.error || 'Échec de l\'envoi de l\'email');
    }
    
    return response;
  } catch (error: any) {
    console.error('Error sending confirmation email:', error);
    throw error;
  }
};