import { Fragment, useEffect, useState, useRef, lazy, Suspense } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Car, Users, Briefcase, Baby, MapPin, X, ArrowRight, ArrowLeft, Mail, Phone, User, Check, Clock, Map as MapIcon } from 'lucide-react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { toast } from 'react-hot-toast';
import emailjs from '@emailjs/browser';
import { EMAILJS_CONFIG, EMAIL_SEND_OPTIONS } from '../config/emailjs';
import { generateBookingNumber, formatBookingNumber } from '../utils/bookingUtils';

interface ReservationModalProps {
  isOpen: boolean;
  onClose: () => void;
  formData: {
    pickup: string;
    dropoff: string;
    date: string;
    time: string;
    pickupPlaceId: string;
    dropoffPlaceId: string;
  };
}

interface VehicleType {
  id: string;
  name: string;
  description: string;
  capacity: number;
  luggage: number;
  pricePerKm: number;
  image: string;
}

interface CustomerInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  additionalInfo: string;
  passengers: number;
  luggage: number;
}

const vehicleTypes: VehicleType[] = [
  {
    id: 'eco',
    name: 'Eco',
    description: 'Confortable et économique',
    capacity: 4,
    luggage: 2,
    pricePerKm: 1.5,
    image: '/vehicles/eco.jpg'
  },
  {
    id: 'berline',
    name: 'Berline',
    description: 'Luxe et confort premium',
    capacity: 4,
    luggage: 3,
    pricePerKm: 2.0,
    image: '/vehicles/berline.jpg'
  },
  {
    id: 'van',
    name: 'Van',
    description: 'Idéal pour les groupes',
    capacity: 7,
    luggage: 6,
    pricePerKm: 2.5,
    image: '/vehicles/van.jpg'
  }
];

const MapComponent = lazy(() => import('./Map'));

export default function ReservationModal({ isOpen, onClose, formData }: ReservationModalProps) {
  const [step, setStep] = useState(1);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formattedBookingNumber, setFormattedBookingNumber] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState('eco');
  const [distance, setDistance] = useState(0);
  const [duration, setDuration] = useState('');
  const [price, setPrice] = useState(0);
  const [options, setOptions] = useState({
    babySeat: false,
    multipleStops: false,
    luggageService: false
  });
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    additionalInfo: '',
    passengers: 1,
    luggage: 1
  });

  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstance = useRef<google.maps.Map | null>(null);
  const directionsRenderer = useRef<google.maps.DirectionsRenderer | null>(null);

  useEffect(() => {
    if (mapRef.current && !mapInstance.current) {
      mapInstance.current = new google.maps.Map(mapRef.current, {
        zoom: 12,
        center: { lat: 48.8566, lng: 2.3522 }, // Paris
        styles: [
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#1d1d1d"
              }
            ]
          },
          {
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#212121"
              }
            ]
          },
          {
            "featureType": "administrative",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#757575"
              },
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "administrative.country",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "administrative.locality",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#bdbdbd"
              }
            ]
          },
          {
            "featureType": "poi",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "color": "#2c2c2c"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#8a8a8a"
              }
            ]
          },
          {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#373737"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#3c3c3c"
              }
            ]
          },
          {
            "featureType": "road.highway.controlled_access",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#4e4e4e"
              }
            ]
          },
          {
            "featureType": "transit",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#000000"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#3d3d3d"
              }
            ]
          }
        ]
      });

      directionsRenderer.current = new google.maps.DirectionsRenderer({
        map: mapInstance.current,
        suppressMarkers: false,
        polylineOptions: {
          strokeColor: '#EAB308', // Couleur jaune pour correspondre au thème
          strokeWeight: 4,
          strokeOpacity: 1,
        },
        markerOptions: {
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 8,
            fillColor: '#EAB308',
            fillOpacity: 1,
            strokeColor: '#000000',
            strokeWeight: 2,
          }
        }
      });
    }

    if (formData.pickupPlaceId && formData.dropoffPlaceId) {
      calculateRoute();
    }
  }, [formData.pickupPlaceId, formData.dropoffPlaceId]);

  useEffect(() => {
    if (distance > 0) {
      calculatePrice();
    }
  }, [distance, selectedVehicle, options]);

  const calculateRoute = () => {
    const directionsService = new google.maps.DirectionsService();
    
    directionsService.route(
      {
        origin: { placeId: formData.pickupPlaceId },
        destination: { placeId: formData.dropoffPlaceId },
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK && result) {
          const route = result.routes[0].legs[0];
          if (route) {
            setDistance(route.distance?.value || 0);
            setDuration(route.duration?.text || '');
            if (directionsRenderer.current) {
              directionsRenderer.current.setDirections(result);
            }
          }
        }
      }
    );
  };

  const calculatePrice = () => {
    const selectedVehicleType = vehicleTypes.find(v => v.id === selectedVehicle);
    if (selectedVehicleType) {
      // Prix minimum de 20€ + prix au kilomètre selon le type de véhicule
      const pricePerKm = (distance / 1000) * selectedVehicleType.pricePerKm;
      const basePrice = 20 + pricePerKm;
      let additionalCosts = 0;
      
      if (options.babySeat) additionalCosts += 10;
      if (options.multipleStops) additionalCosts += 15;
      if (options.luggageService) additionalCosts += 20;
      
      setPrice(Math.round(basePrice + additionalCosts));
    }
  };

  const handleBack = () => {
    setStep(1);
  };

  const handleNext = () => {
    setStep(2);
  };

  const handleSubmit = async () => {
    // Validate required fields
    if (!customerInfo.firstName.trim()) {
      toast.error('Le prénom est requis');
      return;
    }
    if (!customerInfo.lastName.trim()) {
      toast.error('Le nom est requis');
      return;
    }
    if (!customerInfo.email.trim()) {
      toast.error('L\'email est requis');
      return;
    }
    if (!customerInfo.phone.trim()) {
      toast.error('Le téléphone est requis');
      return;
    }
    if (!customerInfo.passengers) {
      toast.error('Le nombre de passagers est requis');
      return;
    }

    try {
      // Générer un numéro de réservation unique
      const bookingNumber = generateBookingNumber();
      const formatted = formatBookingNumber(bookingNumber);
      setFormattedBookingNumber(formatted);

      // Enregistrer dans Firebase
      const reservationData = {
        bookingNumber: formatted,
        firstName: customerInfo.firstName,
        lastName: customerInfo.lastName,
        email: customerInfo.email,
        phone: customerInfo.phone,
        pickup: {
          address: formData.pickup,
          coordinates: null
        },
        dropoff: {
          address: formData.dropoff,
          coordinates: null
        },
        scheduledDate: formData.date,
        scheduledTime: formData.time,
        passengers: parseInt(customerInfo.passengers.toString()),
        luggage: parseInt(customerInfo.luggage.toString()),
        vehicle: {
          name: vehicleTypes.find(v => v.id === selectedVehicle)?.name || selectedVehicle,
          type: selectedVehicle
        },
        price: price,
        status: 'pending',
        createdAt: serverTimestamp(),
        additionalInfo: customerInfo.additionalInfo,
        options: {
          babySeat: options.babySeat,
          multipleStops: options.multipleStops,
          luggageService: options.luggageService
        }
      };

      // Sauvegarder dans Firebase
      const docRef = await addDoc(collection(db, 'bookings'), reservationData);

      // Envoyer l'email de confirmation avec EmailJS
      const emailParams = {
        client_name: `${customerInfo.firstName} ${customerInfo.lastName}`,
        booking_number: formatted,
        date: formData.date,
        time: formData.time,
        pickup_location: formData.pickup,
        dropoff_location: formData.dropoff,
        vehicle_type: vehicleTypes.find(v => v.id === selectedVehicle)?.name || selectedVehicle,
        price: price,
        phone_number: "+33 1 23 45 67 89", // Numéro de téléphone de l'entreprise
        // Ajout d'informations supplémentaires pour l'email
        passengers: customerInfo.passengers,
        luggage: customerInfo.luggage,
        additional_info: customerInfo.additionalInfo || 'Aucune information supplémentaire',
        options: Object.entries(options)
          .filter(([_, value]) => value)
          .map(([key, _]) => {
            switch(key) {
              case 'babySeat': return 'Siège bébé';
              case 'multipleStops': return 'Arrêts multiples';
              case 'luggageService': return 'Service bagages';
              default: return '';
            }
          })
          .join(', ') || 'Aucune option sélectionnée'
      };

      try {
        // Envoi asynchrone de l'email avec les options optimisées
        const emailPromise = emailjs.send(
          EMAILJS_CONFIG.SERVICE_ID,
          EMAILJS_CONFIG.TEMPLATE_ID,
          emailParams,
          EMAILJS_CONFIG.PUBLIC_KEY,
          EMAIL_SEND_OPTIONS
        );

        // Afficher la confirmation immédiatement
        setShowConfirmation(true);
        toast.success('Réservation enregistrée avec succès');

        // Gérer le résultat de l'envoi d'email en arrière-plan
        emailPromise
          .then(() => {
            toast.success('Email de confirmation envoyé');
          })
          .catch((error) => {
            console.error('Erreur lors de l\'envoi de l\'email:', error);
            toast.error('Erreur lors de l\'envoi de l\'email de confirmation');
          });

        // Fermer le modal après 12 secondes
        setTimeout(() => {
          setShowConfirmation(false);
          onClose();
        }, 12000);
      } catch (error) {
        console.error('Erreur lors de l\'enregistrement de la réservation:', error);
        toast.error('Erreur lors de l\'enregistrement');
      }
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement de la réservation:', error);
      toast.error('Erreur lors de l\'enregistrement');
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/75 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-2xl bg-gray-900/95 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                <div className="absolute right-0 top-0 pr-4 pt-4">
                  <button
                    type="button"
                    className="rounded-md text-gray-400 hover:text-gray-200"
                    onClick={onClose}
                  >
                    <span className="sr-only">Fermer</span>
                    <X className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="p-6 sm:p-8">
                  <Dialog.Title as="h3" className="text-2xl font-semibold leading-6 text-white mb-8">
                    {step === 1 ? 'Détails du trajet' : 'Informations client'}
                  </Dialog.Title>

                  {step === 1 ? (
                    <>
                      {/* Carte */}
                      <div 
                        ref={mapRef}
                        className="w-full h-64 rounded-lg overflow-hidden mb-6"
                      >
                        <Suspense fallback={
                          <div className="w-full h-[400px] bg-gray-100 rounded-xl flex items-center justify-center">
                            <div className="animate-pulse flex flex-col items-center">
                              <div className="w-12 h-12 bg-gray-200 rounded-full mb-2"></div>
                              <div className="text-gray-500">Chargement de la carte...</div>
                            </div>
                          </div>
                        }>
                          <MapComponent />
                        </Suspense>
                      </div>

                      {/* Trajet */}
                      <div className="bg-black/20 p-4 rounded-lg mb-6">
                        <div className="flex items-center space-x-4">
                          <MapPin className="text-yellow-500" />
                          <div>
                            <div className="font-medium text-white">{formData.pickup}</div>
                            <div className="text-sm text-gray-400">Départ le {formData.date} à {formData.time}</div>
                          </div>
                        </div>
                        <div className="my-2 border-l-2 border-dashed border-yellow-500 h-8 ml-2" />
                        <div className="flex items-center space-x-4">
                          <MapPin className="text-yellow-500" />
                          <div className="font-medium text-white">{formData.dropoff}</div>
                        </div>
                      </div>

                      {/* Date et Heure */}
                      <div className="grid grid-cols-2 gap-4 mb-6">
                        <div>
                          <label htmlFor="date" className="block text-sm font-medium text-gray-300 mb-1">
                            Date de prise en charge
                          </label>
                          <input
                            type="date"
                            id="date"
                            name="date"
                            min={new Date().toISOString().split('T')[0]}
                            value={formData.date}
                            onChange={(e) => formData.date = e.target.value}
                            className="w-full px-4 py-2.5 bg-black/20 border border-white/10 rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-500"
                            placeholder="Sélectionnez une date"
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="time" className="block text-sm font-medium text-gray-300 mb-1">
                            Heure de prise en charge
                          </label>
                          <input
                            type="time"
                            id="time"
                            name="time"
                            value={formData.time}
                            onChange={(e) => formData.time = e.target.value}
                            className="w-full px-4 py-2.5 bg-black/20 border border-white/10 rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-500"
                            placeholder="Sélectionnez une heure"
                            required
                          />
                          <p className="mt-1 text-sm text-gray-500">Heure de Paris (GMT+1)</p>
                        </div>
                      </div>

                      {/* Détails du trajet */}
                      {distance > 0 && (
                        <div className="grid grid-cols-3 gap-4 text-center mb-6">
                          <div className="bg-black/20 p-3 rounded-lg">
                            <div className="text-sm text-gray-400">Distance</div>
                            <div className="text-lg font-medium text-white">{(distance / 1000).toFixed(1)} km</div>
                          </div>
                          <div className="bg-black/20 p-3 rounded-lg">
                            <div className="text-sm text-gray-400">Durée estimée</div>
                            <div className="text-lg font-medium text-white">{duration}</div>
                          </div>
                          <div className="bg-black/20 p-3 rounded-lg">
                            <div className="text-sm text-gray-400">Prix estimé</div>
                            <div className="text-lg font-medium text-yellow-500">{price}€</div>
                          </div>
                        </div>
                      )}

                      {/* Types de véhicules */}
                      <div className="mb-6">
                        <h4 className="text-lg font-medium text-white mb-4">Choisissez votre véhicule</h4>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                          {vehicleTypes.map((vehicle) => (
                            <button
                              key={vehicle.id}
                              onClick={() => setSelectedVehicle(vehicle.id)}
                              className={`p-4 rounded-lg border ${
                                selectedVehicle === vehicle.id
                                  ? 'border-yellow-500 bg-yellow-500/10'
                                  : 'border-gray-700 hover:border-gray-600'
                              } transition-colors duration-200`}
                            >
                              <div className="aspect-video bg-black/40 rounded-lg mb-3">
                                <img
                                  src={vehicle.image}
                                  alt={vehicle.name}
                                  className="w-full h-full object-cover rounded-lg"
                                />
                              </div>
                              <h5 className="text-lg font-medium text-white">{vehicle.name}</h5>
                              <p className="text-sm text-gray-400 mt-1">{vehicle.description}</p>
                              <div className="mt-2 flex items-center justify-between text-sm text-gray-400">
                                <div className="flex items-center">
                                  <Users className="w-4 h-4 mr-1" />
                                  {vehicle.capacity}
                                </div>
                                <div className="flex items-center">
                                  <Briefcase className="w-4 h-4 mr-1" />
                                  {vehicle.luggage}
                                </div>
                                <div className="text-yellow-500">{vehicle.pricePerKm}€/km</div>
                              </div>
                            </button>
                          ))}
                        </div>
                      </div>

                      {/* Options supplémentaires */}
                      <div className="mb-6">
                        <h4 className="text-lg font-medium text-white mb-4">Options supplémentaires</h4>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                          <label className="flex items-center space-x-3 p-4 rounded-lg border border-gray-700 cursor-pointer">
                            <input
                              type="checkbox"
                              checked={options.babySeat}
                              onChange={(e) => setOptions({ ...options, babySeat: e.target.checked })}
                              className="rounded border-gray-700 text-yellow-500 focus:ring-yellow-500"
                            />
                            <span className="text-white">Siège bébé (+10€)</span>
                          </label>
                          <label className="flex items-center space-x-3 p-4 rounded-lg border border-gray-700 cursor-pointer">
                            <input
                              type="checkbox"
                              checked={options.multipleStops}
                              onChange={(e) => setOptions({ ...options, multipleStops: e.target.checked })}
                              className="rounded border-gray-700 text-yellow-500 focus:ring-yellow-500"
                            />
                            <span className="text-white">Arrêts multiples (+15€)</span>
                          </label>
                          <label className="flex items-center space-x-3 p-4 rounded-lg border border-gray-700 cursor-pointer">
                            <input
                              type="checkbox"
                              checked={options.luggageService}
                              onChange={(e) => setOptions({ ...options, luggageService: e.target.checked })}
                              className="rounded border-gray-700 text-yellow-500 focus:ring-yellow-500"
                            />
                            <span className="text-white">Service bagages (+20€)</span>
                          </label>
                        </div>
                      </div>

                      <div className="flex justify-end">
                        <button
                          type="button"
                          onClick={handleNext}
                          className="inline-flex items-center px-6 py-3 bg-yellow-500 text-black rounded-lg hover:bg-yellow-400 transition-colors duration-300 text-base font-semibold"
                        >
                          Suivant
                          <ArrowRight className="ml-2 w-5 h-5" />
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="space-y-8">
                      {/* Progress Steps */}
                      <div className="flex items-center justify-center mb-8">
                        <div className="flex items-center space-x-4">
                          <div className="flex items-center">
                            <div className="w-8 h-8 rounded-full bg-yellow-500 flex items-center justify-center">
                              <Check className="w-5 h-5 text-black" />
                            </div>
                            <div className="ml-2 text-yellow-500 font-medium">Trajet</div>
                          </div>
                          <div className="w-16 h-0.5 bg-yellow-500"></div>
                          <div className="flex items-center">
                            <div className="w-8 h-8 rounded-full bg-yellow-500 flex items-center justify-center">
                              <User className="w-5 h-5 text-black" />
                            </div>
                            <div className="ml-2 text-yellow-500 font-medium">Informations</div>
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {/* Customer Information Form */}
                        <div className="space-y-6 backdrop-blur-lg bg-white/5 p-6 rounded-2xl border border-white/10">
                          <h4 className="text-lg font-medium text-white">Informations personnelles</h4>
                          <div className="space-y-4">
                            <div className="grid grid-cols-2 gap-4">
                              <div>
                                <label className="block text-sm font-medium text-gray-400 mb-1">Prénom</label>
                                <div className="relative group">
                                  <input
                                    type="text"
                                    value={customerInfo.firstName}
                                    onChange={(e) => setCustomerInfo({ ...customerInfo, firstName: e.target.value })}
                                    className="w-full pl-4 pr-4 py-3 bg-black/40 border border-white/10 rounded-xl focus:ring-2 focus:ring-yellow-500/50 focus:border-yellow-500/50 text-white transition-all duration-300 placeholder-gray-500"
                                    placeholder="John"
                                    required
                                  />
                                  <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-yellow-500/20 to-yellow-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none"></div>
                                </div>
                              </div>
                              <div>
                                <label className="block text-sm font-medium text-gray-400 mb-1">Nom</label>
                                <div className="relative group">
                                  <input
                                    type="text"
                                    value={customerInfo.lastName}
                                    onChange={(e) => setCustomerInfo({ ...customerInfo, lastName: e.target.value })}
                                    className="w-full pl-4 pr-4 py-3 bg-black/40 border border-white/10 rounded-xl focus:ring-2 focus:ring-yellow-500/50 focus:border-yellow-500/50 text-white transition-all duration-300 placeholder-gray-500"
                                    placeholder="Doe"
                                    required
                                  />
                                  <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-yellow-500/20 to-yellow-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none"></div>
                                </div>
                              </div>
                            </div>

                            <div>
                              <label className="block text-sm font-medium text-gray-400 mb-1">Email</label>
                              <div className="relative group">
                                <input
                                  type="email"
                                  value={customerInfo.email}
                                  onChange={(e) => setCustomerInfo({ ...customerInfo, email: e.target.value })}
                                  className="w-full pl-4 pr-4 py-3 bg-black/40 border border-white/10 rounded-xl focus:ring-2 focus:ring-yellow-500/50 focus:border-yellow-500/50 text-white transition-all duration-300 placeholder-gray-500"
                                  placeholder="john.doe@example.com"
                                  required
                                />
                                <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-yellow-500/20 to-yellow-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none"></div>
                              </div>
                            </div>

                            <div>
                              <label className="block text-sm font-medium text-gray-400 mb-1">Téléphone</label>
                              <div className="relative group">
                                <input
                                  type="tel"
                                  value={customerInfo.phone}
                                  onChange={(e) => setCustomerInfo({ ...customerInfo, phone: e.target.value })}
                                  className="w-full pl-4 pr-4 py-3 bg-black/40 border border-white/10 rounded-xl focus:ring-2 focus:ring-yellow-500/50 focus:border-yellow-500/50 text-white transition-all duration-300 placeholder-gray-500"
                                  placeholder="+33 6 12 34 56 78"
                                  required
                                />
                                <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-yellow-500/20 to-yellow-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none"></div>
                              </div>
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                              <div>
                                <label className="block text-sm font-medium text-gray-400 mb-1">Nombre de passagers</label>
                                <div className="relative group">
                                  <select
                                    value={customerInfo.passengers}
                                    onChange={(e) => setCustomerInfo({ ...customerInfo, passengers: parseInt(e.target.value) })}
                                    className="w-full pl-4 pr-10 py-3 bg-black/40 border border-white/10 rounded-xl focus:ring-2 focus:ring-yellow-500/50 focus:border-yellow-500/50 text-white transition-all duration-300 appearance-none"
                                  >
                                    {[1, 2, 3, 4, 5, 6, 7].map((num) => (
                                      <option key={num} value={num}>{num} {num === 1 ? 'passager' : 'passagers'}</option>
                                    ))}
                                  </select>
                                  <Users className="absolute right-3 top-1/2 -translate-y-1/2 w-5 h-5 text-yellow-500 pointer-events-none" />
                                  <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-yellow-500/20 to-yellow-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none"></div>
                                </div>
                              </div>
                              <div>
                                <label className="block text-sm font-medium text-gray-400 mb-1">Nombre de bagages</label>
                                <div className="relative group">
                                  <select
                                    value={customerInfo.luggage}
                                    onChange={(e) => setCustomerInfo({ ...customerInfo, luggage: parseInt(e.target.value) })}
                                    className="w-full pl-4 pr-10 py-3 bg-black/40 border border-white/10 rounded-xl focus:ring-2 focus:ring-yellow-500/50 focus:border-yellow-500/50 text-white transition-all duration-300 appearance-none"
                                  >
                                    {[1, 2, 3, 4, 5, 6].map((num) => (
                                      <option key={num} value={num}>{num} {num === 1 ? 'bagage' : 'bagages'}</option>
                                    ))}
                                  </select>
                                  <Briefcase className="absolute right-3 top-1/2 -translate-y-1/2 w-5 h-5 text-yellow-500 pointer-events-none" />
                                  <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-yellow-500/20 to-yellow-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none"></div>
                                </div>
                              </div>
                            </div>

                            <div>
                              <label className="block text-sm font-medium text-gray-400 mb-1">Informations supplémentaires</label>
                              <div className="relative group">
                                <textarea
                                  value={customerInfo.additionalInfo}
                                  onChange={(e) => setCustomerInfo({ ...customerInfo, additionalInfo: e.target.value })}
                                  className="w-full pl-4 pr-4 py-3 bg-black/40 border border-white/10 rounded-xl focus:ring-2 focus:ring-yellow-500/50 focus:border-yellow-500/50 text-white transition-all duration-300 placeholder-gray-500 resize-none"
                                  placeholder="Ex: Instructions spéciales, besoins particuliers..."
                                  rows={3}
                                />
                                <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-yellow-500/20 to-yellow-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none"></div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Order Summary */}
                        <div className="space-y-6 backdrop-blur-lg bg-white/5 p-6 rounded-2xl border border-white/10">
                          <h4 className="text-lg font-medium text-white">Résumé de la réservation</h4>
                          
                          <div className="space-y-4">
                            <div className="flex items-center justify-between py-3 border-b border-white/10">
                              <div className="flex items-center space-x-3">
                                <Car className="text-yellow-500" />
                                <span className="text-gray-400">Véhicule</span>
                              </div>
                              <span className="text-white font-medium">{vehicleTypes.find(v => v.id === selectedVehicle)?.name}</span>
                            </div>

                            <div className="flex items-center justify-between py-3 border-b border-white/10">
                              <div className="flex items-center space-x-3">
                                <MapPin className="text-yellow-500" />
                                <span className="text-gray-400">Distance</span>
                              </div>
                              <span className="text-white font-medium">{(distance / 1000).toFixed(1)} km</span>
                            </div>

                            <div className="flex items-center justify-between py-3 border-b border-white/10">
                              <div className="flex items-center space-x-3">
                                <Clock className="text-yellow-500" />
                                <span className="text-gray-400">Durée estimée</span>
                              </div>
                              <span className="text-white font-medium">{duration}</span>
                            </div>

                            {options.babySeat && (
                              <div className="flex items-center justify-between py-3 border-b border-white/10">
                                <div className="flex items-center space-x-3">
                                  <Baby className="text-yellow-500" />
                                  <span className="text-gray-400">Siège bébé</span>
                                </div>
                                <span className="text-white font-medium">+10€</span>
                              </div>
                            )}

                            {options.multipleStops && (
                              <div className="flex items-center justify-between py-3 border-b border-white/10">
                                <div className="flex items-center space-x-3">
                                  <MapIcon className="text-yellow-500" />
                                  <span className="text-gray-400">Arrêts multiples</span>
                                </div>
                                <span className="text-white font-medium">+15€</span>
                              </div>
                            )}

                            {options.luggageService && (
                              <div className="flex items-center justify-between py-3 border-b border-white/10">
                                <div className="flex items-center space-x-3">
                                  <Briefcase className="text-yellow-500" />
                                  <span className="text-gray-400">Service bagages</span>
                                </div>
                                <span className="text-white font-medium">+20€</span>
                              </div>
                            )}

                            <div className="flex items-center justify-between pt-4">
                              <span className="text-lg text-white font-medium">Total</span>
                              <span className="text-xl text-yellow-500 font-bold">{price}€</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Navigation Buttons */}
                      <div className="flex justify-between pt-6">
                        <button
                          type="button"
                          onClick={handleBack}
                          className="px-6 py-3 bg-white/5 hover:bg-white/10 border border-white/10 rounded-xl text-white font-medium transition-all duration-300 flex items-center space-x-2 group"
                        >
                          <ArrowLeft className="w-5 h-5 group-hover:-translate-x-1 transition-transform duration-300" />
                          <span>Retour</span>
                        </button>
                        <button
                          type="button"
                          onClick={handleSubmit}
                          className="px-6 py-3 bg-yellow-500 hover:bg-yellow-400 rounded-xl text-black font-medium transition-all duration-300 flex items-center space-x-2 group"
                        >
                          <span>Confirmer</span>
                          <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>

        {/* Confirmation Message */}
        {showConfirmation && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-gray-900/90 p-8 rounded-2xl border border-yellow-500/20 shadow-2xl transform animate-fade-scale-up">
              <div className="flex flex-col items-center text-center space-y-4">
                <div className="w-16 h-16 rounded-full bg-yellow-500/20 flex items-center justify-center">
                  <Check className="w-8 h-8 text-yellow-500" />
                </div>
                <h3 className="text-2xl font-bold text-white">Réservation confirmée !</h3>
                <p className="text-gray-400">
                  Votre réservation a été enregistrée avec succès.
                </p>
                <div className="bg-black/30 p-4 rounded-xl mt-4 w-full">
                  <p className="text-sm text-gray-400 mb-2">Numéro de réservation</p>
                  <p className="text-xl font-mono text-yellow-500">{formattedBookingNumber}</p>
                </div>
                <p className="text-sm text-gray-400 mt-4">
                  Un email de confirmation a été envoyé à {customerInfo.email}.<br />
                  Conservez votre numéro de réservation.
                </p>
                <button
                  type="button"
                  onClick={onClose}
                  className="mt-6 px-6 py-3 bg-yellow-500 hover:bg-yellow-400 rounded-xl text-black font-medium transition-colors"
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        )}
      </Dialog>
    </Transition.Root>
  );
}
