import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { sendConfirmationEmail } from './emailService';
import type { BookingFormData } from '../types';
import toast from 'react-hot-toast';

export const createBooking = async (bookingData: BookingFormData) => {
  try {
    // Create booking in Firestore first
    const bookingsRef = collection(db, 'bookings');
    const newBooking = {
      ...bookingData,
      status: 'pending',
      createdAt: serverTimestamp(),
    };
    
    const docRef = await addDoc(bookingsRef, newBooking);
    
    // Show success message for booking creation
    toast.success('Réservation enregistrée avec succès');

    // Attempt to send confirmation email
    try {
      await sendConfirmationEmail({
        ...newBooking,
        id: docRef.id,
        formattedPrice: `${newBooking.price}€`,
        formattedDate: new Date(newBooking.scheduledDate).toLocaleDateString('fr-FR', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }),
        formattedTime: newBooking.scheduledTime
      });
      
      toast.success('Un email de confirmation vous a été envoyé');
    } catch (emailError) {
      console.error('Error sending confirmation email:', emailError);
      toast.error('L\'email de confirmation n\'a pas pu être envoyé, mais votre réservation est bien enregistrée');
    }

    return docRef.id;
  } catch (error) {
    console.error('Error creating booking:', error);
    toast.error('Erreur lors de la création de la réservation');
    throw error;
  }
};