import React, { useState, useEffect, useRef } from 'react';
import { MessageCircle, Send, X, ChevronDown, User, Loader2 } from 'lucide-react';
import { collection, addDoc, serverTimestamp, query, orderBy, onSnapshot, where, doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { v4 as uuidv4 } from 'uuid';
import { motion, AnimatePresence } from 'framer-motion';
import { getRandomAgent } from '../config/chatAgents';
import { getAIResponse } from '../services/aiService';
import Logo from '../assets/logo.svg';
import { useLocation } from 'react-router-dom';

interface Message {
  id: string;
  content: string;
  isBot: boolean;
  timestamp: any;
  sessionId: string;
  status: 'read' | 'unread';
}

interface UserInfo {
  name: string;
  email: string;
}

const AIChat: React.FC = () => {
  const location = useLocation();
  
  // Ne pas afficher le chat sur les pages d'administration
  if (location.pathname.startsWith('/admin') || location.pathname.startsWith('/dashboard')) {
    return null;
  }

  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [isTyping, setIsTyping] = useState(false);
  const [sessionId] = useState(uuidv4());
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [agent] = useState(getRandomAgent());
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (isOpen && userInfo) {
      const q = query(
        collection(db, 'chat_messages'),
        where('sessionId', '==', sessionId)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const newMessages: Message[] = [];
        snapshot.forEach((doc) => {
          const messageData = doc.data() as Message;
          newMessages.push({ ...messageData, id: doc.id });
        });
        newMessages.sort((a, b) => {
          const timeA = a.timestamp?.toMillis() || 0;
          const timeB = b.timestamp?.toMillis() || 0;
          return timeA - timeB;
        });
        setMessages(newMessages);
        scrollToBottom();
      });

      return () => unsubscribe();
    }
  }, [isOpen, sessionId, userInfo]);

  useEffect(() => {
    if (isOpen && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, isOpen]);

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      setShowScrollButton(scrollHeight - scrollTop - clientHeight > 100);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const saveMessageToFirebase = async (content: string, isBot: boolean, userName?: string, userEmail?: string) => {
    try {
      // Créer ou mettre à jour la conversation
      const conversationRef = doc(db, 'conversations', sessionId);
      const conversationData = {
        id: sessionId,
        userName: userName || 'Anonymous',
        userEmail: userEmail || 'anonymous@example.com',
        lastMessage: content,
        lastMessageTimestamp: serverTimestamp(),
        status: 'active',
        agentName: agent.name,
        agentRole: agent.role,
        messagesCount: messages.length + 1,
        createdAt: messages.length === 0 ? serverTimestamp() : undefined,
        updatedAt: serverTimestamp()
      };

      if (messages.length === 0) {
        // Nouvelle conversation
        await setDoc(conversationRef, conversationData);
      } else {
        // Mettre à jour la conversation existante
        await updateDoc(conversationRef, {
          lastMessage: content,
          lastMessageTimestamp: serverTimestamp(),
          messagesCount: messages.length + 1,
          updatedAt: serverTimestamp()
        });
      }

      // Sauvegarder le message
      const messageData: any = {
        content,
        isBot,
        timestamp: serverTimestamp(),
        sessionId,
        status: 'unread',
        userName: userName || 'Anonymous',
        userEmail: userEmail || 'anonymous@example.com',
        conversationId: sessionId
      };

      // Ajouter les informations de l'agent seulement si c'est un message bot
      if (isBot) {
        messageData.agentName = agent.name;
        messageData.agentRole = agent.role;
      }

      await addDoc(collection(db, 'chat_messages'), messageData);
    } catch (error) {
      console.error('Error saving message:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!message.trim() || !userInfo || isProcessing) return;

    const userMessage = message.trim();
    setMessage('');
    setIsProcessing(true);
    setIsTyping(true);

    try {
      await saveMessageToFirebase(userMessage, false, userInfo.name, userInfo.email);

      const aiResponse = await getAIResponse(userMessage);
      
      await saveMessageToFirebase(aiResponse, true, userInfo.name, userInfo.email);
    } catch (error) {
      console.error('Error in chat interaction:', error);
      await saveMessageToFirebase(
        "Je suis désolé, je rencontre des difficultés techniques. Comment puis-je vous aider autrement ?",
        true,
        userInfo.name,
        userInfo.email
      );
    } finally {
      setIsTyping(false);
      setIsProcessing(false);
    }
  };

  const handleUserInfoSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const name = (form.elements.namedItem('name') as HTMLInputElement).value.trim();
    const email = (form.elements.namedItem('email') as HTMLInputElement).value.trim();

    if (!name || !email) return;

    setUserInfo({ name, email });
    
    try {
      // Créer la conversation dans Firebase
      const conversationRef = doc(db, 'conversations', sessionId);
      await setDoc(conversationRef, {
        id: sessionId,
        userName: name,
        userEmail: email,
        status: 'active',
        agentName: agent.name,
        agentRole: agent.role,
        messagesCount: 0,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });

      const welcomeMessage = `Bonjour ${name} ! Je suis ${agent.name}, votre ${agent.role}. Comment puis-je vous aider aujourd'hui ?`;
      await saveMessageToFirebase(welcomeMessage, true, name, email);
    } catch (error) {
      console.error('Error initializing conversation:', error);
    }
  };

  const ContactForm = () => (
    <div className="p-6">
      <div className="text-center mb-6">
        <img src={Logo} alt="Elite Transfer" className="h-12 mx-auto mb-4" />
        <h2 className="text-xl font-semibold mb-2">Bienvenue chez Elite Transfer</h2>
        <p className="text-gray-600 dark:text-gray-400">
          Votre partenaire de confiance pour vos déplacements
        </p>
      </div>
      <form onSubmit={handleUserInfoSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Votre nom
          </label>
          <input
            type="text"
            id="name"
            name="name"
            required
            className="w-full p-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-primary focus:border-transparent transition-all"
            placeholder="John Doe"
          />
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Votre email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            required
            className="w-full p-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-primary focus:border-transparent transition-all"
            placeholder="john@example.com"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-gradient-to-r from-blue-600 to-blue-500 text-white py-2 px-4 rounded-lg hover:from-blue-700 hover:to-blue-600 transition-all transform hover:scale-[1.02] active:scale-[0.98] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Démarrer la conversation
        </button>
      </form>
    </div>
  );

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 20, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 20, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className="absolute bottom-16 right-0 w-96 bg-white dark:bg-gray-800 rounded-lg shadow-xl border border-gray-200 dark:border-gray-700"
          >
            {/* En-tête du chat */}
            <div className="p-4 border-b dark:border-gray-700 bg-gradient-to-r from-blue-600 to-blue-500">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center space-x-2">
                  <img src={Logo} alt="Elite Transfer" className="h-8" />
                  <h2 className="text-lg font-semibold text-white">Chat Support</h2>
                </div>
                <button
                  onClick={() => setIsOpen(false)}
                  className="text-white/80 hover:text-white transition-colors"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
              {userInfo && (
                <div className="flex items-center space-x-3 bg-white/10 p-2 rounded-lg">
                  <img
                    src={agent.avatar}
                    alt={agent.name}
                    className="w-10 h-10 rounded-full object-cover border-2 border-white"
                    onError={(e) => {
                      const img = e.target as HTMLImageElement;
                      img.src = 'https://ui-avatars.com/api/?name=' + encodeURIComponent(agent.name);
                    }}
                  />
                  <div className="text-white">
                    <div className="font-medium">{agent.name}</div>
                    <div className="text-sm text-white/80">{agent.role}</div>
                  </div>
                  <div className="ml-auto flex items-center space-x-1">
                    <span className="w-2 h-2 bg-green-400 rounded-full animate-pulse" />
                    <span className="text-sm text-white/80">En ligne</span>
                  </div>
                </div>
              )}
            </div>

            {!userInfo ? (
              <ContactForm />
            ) : (
              <>
                {/* Zone des messages */}
                <div
                  ref={chatContainerRef}
                  onScroll={handleScroll}
                  className="h-96 overflow-y-auto p-4 space-y-4 bg-gray-50 dark:bg-gray-800"
                >
                  {messages.map((msg) => (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.2 }}
                      key={msg.id}
                      className={`flex ${msg.isBot ? 'justify-start' : 'justify-end'}`}
                    >
                      <div
                        className={`max-w-[80%] p-3 rounded-lg shadow-sm ${
                          msg.isBot
                            ? 'bg-white dark:bg-gray-700'
                            : 'bg-gradient-to-r from-blue-600 to-blue-500 text-white'
                        }`}
                      >
                        {msg.isBot && (
                          <div className="flex items-center space-x-2 mb-1">
                            <img
                              src={agent.avatar}
                              alt={agent.name}
                              className="w-6 h-6 rounded-full object-cover border border-gray-200"
                              onError={(e) => {
                                const img = e.target as HTMLImageElement;
                                img.src = 'https://ui-avatars.com/api/?name=' + encodeURIComponent(agent.name);
                              }}
                            />
                            <span className="text-xs font-medium">{agent.name}</span>
                          </div>
                        )}
                        <p className="text-sm whitespace-pre-wrap">{msg.content}</p>
                      </div>
                    </motion.div>
                  ))}
                  {isTyping && (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      className="flex justify-start"
                    >
                      <div className="bg-white dark:bg-gray-700 p-3 rounded-lg shadow-sm">
                        <div className="flex items-center space-x-2">
                          <Loader2 className="w-4 h-4 animate-spin text-gray-400" />
                          <span className="text-sm text-gray-500">En train d'écrire...</span>
                        </div>
                      </div>
                    </motion.div>
                  )}
                  <div ref={messagesEndRef} />
                </div>

                {showScrollButton && (
                  <motion.button
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    onClick={scrollToBottom}
                    className="absolute bottom-20 right-4 bg-white dark:bg-gray-700 p-2 rounded-full shadow-lg hover:shadow-xl transition-all transform hover:scale-110"
                  >
                    <ChevronDown className="w-4 h-4" />
                  </motion.button>
                )}

                {/* Zone de saisie */}
                <form onSubmit={handleSubmit} className="p-4 border-t dark:border-gray-700 bg-white dark:bg-gray-800">
                  <div className="flex items-center space-x-2">
                    <input
                      type="text"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Tapez votre message..."
                      className="flex-1 p-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-primary focus:border-transparent transition-all"
                      disabled={isProcessing}
                    />
                    <button
                      type="submit"
                      disabled={isProcessing || !message.trim()}
                      className="bg-gradient-to-r from-blue-600 to-blue-500 text-white p-2 rounded-lg hover:from-blue-700 hover:to-blue-600 transition-all transform hover:scale-105 active:scale-95 disabled:opacity-50 disabled:cursor-not-allowed disabled:transform-none"
                    >
                      <Send className="w-5 h-5" />
                    </button>
                  </div>
                </form>
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>

      {/* Bouton pour ouvrir le chat */}
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setIsOpen(!isOpen)}
        className="bg-gradient-to-r from-blue-600 to-blue-500 text-white p-3 rounded-full shadow-lg hover:shadow-xl transition-shadow"
      >
        {isOpen ? <X className="w-6 h-6" /> : <MessageCircle className="w-6 h-6" />}
      </motion.button>
    </div>
  );
};

export default AIChat;
