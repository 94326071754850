import React from 'react';
import { Edit, Trash2, CheckCircle, MessageCircle, Download, Share2 } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface BookingContextMenuProps {
  isOpen: boolean;
  position: { x: number; y: number };
  onClose: () => void;
  onAction: (action: string) => void;
  bookingStatus: string;
}

const BookingContextMenu: React.FC<BookingContextMenuProps> = ({
  isOpen,
  position,
  onClose,
  onAction,
  bookingStatus
}) => {
  if (!isOpen) return null;

  const menuItems = [
    { icon: Edit, label: 'Modifier', action: 'edit' },
    { icon: CheckCircle, label: 'Changer le statut', action: 'status' },
    { icon: MessageCircle, label: 'Envoyer un message', action: 'message' },
    { icon: Download, label: 'Exporter', action: 'export' },
    { icon: Share2, label: 'Partager', action: 'share' },
    { icon: Trash2, label: 'Supprimer', action: 'delete', className: 'text-red-500' }
  ];

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <div
            className="fixed inset-0 z-50"
            onClick={onClose}
          />
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="fixed z-50 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-2 min-w-[200px]"
            style={{
              left: position.x,
              top: position.y,
            }}
          >
            {menuItems.map((item) => (
              <button
                key={item.action}
                onClick={() => onAction(item.action)}
                className={`w-full flex items-center px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-700 rounded ${item.className || ''}`}
              >
                <item.icon className="w-4 h-4 mr-3" />
                {item.label}
              </button>
            ))}
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default BookingContextMenu;
