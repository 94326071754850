import emailjs from '@emailjs/browser';

// Configuration EmailJS
export const EMAILJS_CONFIG = {
  SERVICE_ID: "service_a5iuc9w",
  TEMPLATE_ID: "template_nuiphap",
  PUBLIC_KEY: "_yxr_4k4q1N2ozuJT"
};

// Configuration des options d'envoi pour optimiser la vitesse
export const EMAIL_SEND_OPTIONS = {
  // Activer l'envoi asynchrone
  async: true,
  // Définir un timeout plus court (10 secondes)
  timeout: 10000
};

// Initialisation d'EmailJS avec votre Public Key
emailjs.init(EMAILJS_CONFIG.PUBLIC_KEY, {
  // Mode strict désactivé pour des performances optimales
  strict: false
});
