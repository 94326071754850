import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from './context/ThemeContext';
import AuthProvider from './components/AuthProvider';
import ProtectedRoute from './components/ProtectedRoute';
import Layout from './components/Layout';
import Eliteadmin92 from './pages/Eliteadmin92';
import AdminProfile from './pages/AdminProfile';
import Login from './pages/Login';
import AdminDashboardNew from './pages/AdminDashboardNew';
import AdminLoginNew from './pages/AdminLoginNew';
import AIChat from './components/AIChat';
import ChatManagement from './pages/ChatManagement';

function App() {
  return (
    <ThemeProvider>
      <div className="min-h-screen bg-white dark:bg-dark-bg text-gray-900 dark:text-gray-100 transition-colors duration-200">
        <Router>
          <AuthProvider>
            <Toaster position="top-right" />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route 
                path="/eliteadmin92" 
                element={
                  <ProtectedRoute>
                    <Eliteadmin92 />
                  </ProtectedRoute>
                } 
              />
              <Route 
                path="/admin/profile" 
                element={
                  <ProtectedRoute>
                    <AdminProfile />
                  </ProtectedRoute>
                } 
              />
              <Route path="/admin/login" element={<AdminLoginNew />} />
              <Route 
                path="/admin/dashboard" 
                element={
                  <ProtectedRoute>
                    <AdminDashboardNew />
                  </ProtectedRoute>
                } 
              />
              <Route 
                path="/admin/chat" 
                element={
                  <ProtectedRoute>
                    <ChatManagement />
                  </ProtectedRoute>
                } 
              />
              <Route path="/*" element={<Layout />} />
            </Routes>
            {/* Ajout du chat AI */}
            <AIChat />
          </AuthProvider>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;