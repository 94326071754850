import { lazy, Suspense } from 'react';
import HeroSlider from '../components/HeroSlider';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Star, CheckCircle2, ArrowRight } from 'lucide-react';

// Lazy loading des composants moins critiques
const About = lazy(() => import('../components/About'));
const Services = lazy(() => import('../components/Services'));
const Testimonials = lazy(() => import('../components/Testimonials'));
const FAQ = lazy(() => import('../components/FAQ'));
const Contact = lazy(() => import('../components/Contact'));
const ContactForm = lazy(() => import('../components/ContactForm'));

// Composant de chargement
const LoadingComponent = () => (
  <div className="w-full h-48 flex items-center justify-center">
    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-yellow-500"></div>
  </div>
);

export default function HomePage() {
  return (
    <div className="bg-white dark:bg-neutral-900 transition-colors duration-300">
      {/* Hero Section - chargé immédiatement car critique */}
      <div className="scroll-mt-16">
        <HeroSlider />
      </div>

      {/* Sections avec lazy loading */}
      <Suspense fallback={<LoadingComponent />}>
        <div id="about" className="scroll-mt-16">
          <About />
        </div>

        <div id="services" className="scroll-mt-16">
          <Services />
        </div>

        <div id="testimonials" className="scroll-mt-16">
          <Testimonials />
        </div>

        <div id="faq" className="scroll-mt-16">
          <FAQ />
        </div>

        <div id="contact" className="scroll-mt-16">
          <Contact />
        </div>
      </Suspense>
    </div>
  );
}