// OpenAI API configuration
export const AI_CONFIG = {
  API_URL: "https://api.openai.com/v1/chat/completions",
  API_KEY: "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",
  MODEL: "gpt-3.5-turbo",
  MAX_TOKENS: 100, // Réduit pour GPT-3.5
  TEMPERATURE: 0.5, // Réduit pour plus de cohérence
  PRESENCE_PENALTY: 0.3, // Ajusté pour GPT-3.5
  FREQUENCY_PENALTY: 0.3, // Ajusté pour GPT-3.5
};

// Prompt système pour l'assistant
export const SYSTEM_PROMPT = `Vous êtes l'assistant virtuel d'Elite Transfer, un service de transport VTC haut de gamme. 
Votre rôle est d'aider les clients avec leurs réservations et questions.
Répondez de manière professionnelle, concise et courtoise.
Concentrez-vous sur les services de transport et la réservation.
Évitez les réponses trop longues ou hors sujet.`;
