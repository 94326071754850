import { create } from 'zustand';
import type { Vehicle } from '../types';

interface BookingState {
  pickup: { address: string; coordinates: [number, number] } | null;
  dropoff: { address: string; coordinates: [number, number] } | null;
  selectedVehicle: Vehicle | null;
  distance: number | null;
  price: number | null;
  journeyTime: string | null;
  setPickup: (pickup: { address: string; coordinates: [number, number] } | null) => void;
  setDropoff: (dropoff: { address: string; coordinates: [number, number] } | null) => void;
  setSelectedVehicle: (vehicle: Vehicle | null) => void;
  setDistance: (distance: number | null) => void;
  setJourneyTime: (time: string | null) => void;
  calculatePrice: () => void;
  reset: () => void;
}

const MIN_PRICE = 20;

export const useBookingStore = create<BookingState>((set, get) => ({
  pickup: null,
  dropoff: null,
  selectedVehicle: null,
  distance: null,
  price: null,
  journeyTime: null,
  setPickup: (pickup) => set({ pickup }),
  setDropoff: (dropoff) => set({ dropoff }),
  setSelectedVehicle: (vehicle) => {
    set({ selectedVehicle: vehicle });
    get().calculatePrice();
  },
  setDistance: (distance) => {
    set({ distance });
    get().calculatePrice();
  },
  setJourneyTime: (time) => set({ journeyTime: time }),
  calculatePrice: () => {
    const { selectedVehicle, distance } = get();
    if (!selectedVehicle || distance === null) {
      set({ price: null });
      return;
    }

    const basePrice = selectedVehicle.basePrice;
    const pricePerKm = selectedVehicle.pricePerKm;
    const calculatedPrice = basePrice + (distance * pricePerKm);
    
    const finalPrice = Math.max(calculatedPrice, MIN_PRICE);
    set({ price: Math.round(finalPrice * 100) / 100 });
  },
  reset: () => set({ 
    pickup: null, 
    dropoff: null, 
    selectedVehicle: null, 
    distance: null, 
    price: null,
    journeyTime: null
  }),
}));